import React, { Component } from 'react';
import "../Content.css";
import dog5 from '../../images/dogs/dog005.png';
import collie2 from '../../images/dogs/collie2.png';
import dogWhiskers from '../../images/dogs/dogWhiskers.png';
import dog4 from '../../images/dogs/dog004.png';
import dog17 from '../../images/dogs/dog017.png';

export default class Nutrition extends Component {
    render() {
        return (
            <div className="C-width">
                <h1>Nutrition</h1>
                <p>
                    "The positive impact of proper nutrition on health and disease is well established in all animals. Appropriate feeding throughout all life stages
                    can help prevent diet-associated diseases, as well as to assist in the management of other
                    diseases." &mdash; <a target="_blank" rel="noopener noreferrer" href='https://www.aaha.org/public_documents/professional/guidelines/nutritionalassessmentguidelines.pdf'><em>AAHA Nutritional Assessment Guidelines for Dogs and Cats</em></a>
                </p>
                <h2 className="H-turn">Main Points</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Grain-free diets, diets from smaller manufacturers, and exotic-protein diets should be avoided until we better understand their link to heart disease.
                </p>
                <p>
                    Many grain-free diets have been linked to a form of heart disease called dilated cardiomyopathy (DCM).  Additional information can be found in this <a target="_blank" rel="noopener noreferrer" href='https://petparenthandouts.com/diet-associated-dcm'>Pet Parent Handout</a> and in <a target="_blank" rel="noopener noreferrer" href='https://www.fda.gov/animal-veterinary/news-events/fda-investigation-potential-link-between-certain-diets-and-canine-dilated-cardiomyopathy'>this update from the FDA</a>.  Approximately one-third of dogs with DCM die suddenly with no obvious signs of disease.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Feed your dog high quality food appropriate for your dog's life stage from a trusted pet food brand.
                </p>
                <p>
                    An AAFCO Nutritional Adequacy Statement that indicates the food is complete and balanced for the appropriate life stage of dog is a good place to start.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Monitor your dog's weight and body condition score to ensure its food intake is appropriate.
                </p>
                <p>
                    Dog obesity has been linked to several health problems.  The nutritional requirements of individual dogs vary with breed, activity level, and life stage among other factors.  Feeding an appropriate quantity and keeping treats to less than 10% of the daily calories consumed by your dog can help ensure your dog lives a long, healthy life.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Talk with your veterinarian to find a specific diet and feeding plan that work for your dog.
                </p>
                <p>
                    What and how you feed your individual four-legged family member is an important part of the bond you create with your dog. Have a conversation with your veterinarian at each visit to make sure you understand and meet the unique nutritional requirements of your dog.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={dog5} alt="Dog" />
                </div>
                <h2 className="H-turn Right">Quality</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    An AAFCO Nutritional Adequacy Statement found on your bag of dog food will tell you if the diet is complete and balanced and for what life stage it is intended.
                </p>
                <p>
                    This statement is not a guarantee that the food is appropriate for your dog, but it's an indication that you're on the right track.
                    Hill's, Purina, and Royal Canin are the three big names in pet food and are generally well-trusted by the veterinary community.
                    There are many other pet food manufacturers, and some of them also make quality food. Raw food diets increase the risk of <em>Salmonella</em> and <em>Listeria</em>, which cause dangerous disease in your dog, so these are not usually recommended by veterinarians. These diets also put human family members—especially the young, old, and immunocompromised—at risk of disease.  A list of FDA recalls can be
                    found <a target="_blank" rel="noopener noreferrer" href='https://www.fda.gov/AnimalVeterinary/SafetyHealth/RecallsWithdrawals/default.htm'>here</a> and demonstrates several recent recalls attributed
                    to <em>Salmonella</em> and <em>Listeria</em>. Multiple studies indicate that most homemade diets are not balanced or complete<sup>1</sup>, so these diets are usually not recommended by veterinarians.  <a target="_blank" rel="noopener noreferrer" href='https://secure.balanceit.com'>Balance IT</a> helps create well-balanced recipes and provides vitamins to help ensure home-cooked meals are complete. A great deal of marketing goes into pet foods,
                    and it's important to remember that the guaranteed analysis (the amounts of protein, fat, fiber, and moisture) published on the bag of pet food is not an indication of nutritional quality.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={collie2} alt="Dog" />
                </div>
                <h2 className="H-turn">Quantity</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    The amount you feed your dog and your dog's subsequent caloric intake are important in preventing the health consequences associated with canine obesity.
                </p>
                <p>
                    Many diseases in dogs have known associations with obesity, and too many dogs in the U.S. are overweight.  Weighing your dog monthly and recording these weights over time can help you make sure your dog is getting enough, but not too many calories. You can also monitor your dog's body condition score, or BCS, monthly
                    to make sure your dog is staying at a healthy weight.  Check out <a target="_blank" rel="noopener noreferrer" href="http://www.wsava.org/WSAVA/media/Arpita-and-Emma-editorial/Body-Condition-Score-Dog.pdf">this resource</a> from the World Small Animal Veterinary Association  for more information on how to monitor the BCS of your dog.
                    Growing puppies and reproducing dogs require more calories and often a more energy dense food than adult dogs that have been spayed or neutered.  It's important to remember that treats should not make up more than 10% of your dog's caloric intake.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={dogWhiskers} alt="Dog" />
                </div>
                <h2 className="H-turn Right">Feeding Plan</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Some dogs can maintain a healthy weight with free access to a full food bowl, but others require a more structured feeding plan.
                </p>
                <p>
                    Giving free access to food can be convenient for pet parents, and works well for some dogs, but others will inevitably overeat which may have health consequences.  Regularly scheduled meals two to three times per day can prevent weight gain associated with overeating, improve your dog's health, and offer training opportunities around meal time.  Talk with your veterinarian about the best feeding plan for your dog.
                </p>
                <div className="Center">
                    <img src={dog4} alt="Dog" />
                </div>
                <h2 className="H-turn">Additional Resources</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Web Links
                </p>
                <p>
                    <a target="_blank" rel="noopener noreferrer" href='https://vet.osu.edu/vmc/companion/our-services/nutrition-support-service/nutrition-consult-request'><em>The Ohio State University</em></a> is available for veterinary nutrition consultation and can help tailor a diet, home-cooked or commercial, to your individual pet.
                </p>
                <p>
                    <a target="_blank" rel="noopener noreferrer" href='https://secure.balanceit.com'>Balance IT</a> was created by a boarded veterinary nutritionist to help ensure home-cooked diets are complete and balanced.
                </p>
                <p>
                    <a target="_blank" rel="noopener noreferrer" href='https://www.amazon.com/gp/product/B00P1CCBBS/ref=ox_sc_act_title_1?smid=A1Z9ZYA3TVLUDO&psc=1'>Interactive Feeding Toys</a>, such as this Magic Mushroom Meal Dispenser, can help make meal time fun and engaging and keep your dog occupied.
                </p>
                <div className="Center">
                    <img src={dog17} alt="Dog" />
                </div>
            </div>
        );
    }

}
