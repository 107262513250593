import React, { Component } from 'react';
import "../Content.css";
import cat24 from '../../images/cats/cat024.png';
import cat30 from '../../images/cats/cat030.png';
import cat25 from '../../images/cats/cat025.png';

export default class CatToxins extends Component {
    render() {
        return (
            <div className="C-width">
                <h1>Toxins</h1>
                <p>
                    This page contains some of the most common toxins that threaten the health of your cat.  It is by no means a complete list, and even non-toxic things can become toxic if your cat consumes enough of them. The <a target="_blank" rel="noopener noreferrer" href="http://www.petpoisonhelpline.com/">Pet Poison Helpline</a> is a great resource with a lot of information.  If you suspect that your cat is experiencing a toxicity, do not hesitate to contact your veterinarian or bring your cat to the closest emergency veterinary clinic immediately.
                </p>
                <h2 className="H-turn">Foods</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Chocolate
                </p>
                <p>
                    Theobromine and caffeine found in cocoa and cocoa-based products are extremely toxic to cats.  Usually, darker chocolates contain more of these toxins, but, in great enough quantities, milk chocolate can be just as harmful. Depending on the amount ingested, symptoms range from vomiting and diarrhea to abnormal heart rhythms and seizures.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Alcohol
                </p>
                <p>
                    Adult beverages are for human adults, not cats. Even a small amount of alcohol can be toxic to your cat.  Friends don't let four-legged friends drink alcohol.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Grapes
                </p>
                <p>
                    Grapes can cause acute kidney failure in cats.  Remember that raisins are dried grapes, and when covered in chocolate, they are especially dangerous.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Garlic
                </p>
                <p>
                    Garlic causes damage to the red blood cells of cats, reducing your cat's ability to get oxygen to the tissues.  Onions and other close relatives of garlic should also be avoided.  These are dangerous because they are often found in broths and other human-safe foods.  Do not share soup with your cat.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={cat24} alt="Cat" />
                </div>
                <h2 className="H-turn Right">Plants</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Lilies
                </p>
                <p>
                    Some lilies are extremely toxic to cats, and <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=-Has_FBeMpY">this video</a> from the Pet Poison Helpline can help you identify them.  Even a very small amount of lily can be deadly, so don't bring lilies into your home.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Sago Palm
                </p>
                <p>
                    Just a small amount of this common ornamental plant can cause serious liver disease in your cat.  Do not give your cat any access to sago palms or relatives of this plant.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Yew
                </p>
                <p>
                    Another common ornamental, the yew, can cause seizures and death in cats.  Do not use this plant where your cat might have access to it.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Azalea or Rhododendron
                </p>
                <p>
                    There are many varieties of azaleas and rhododendrons and several are extremely toxic to cats.  Take care to keep your cat away from this threat.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={cat30} alt="Cat" />
                </div>
                <h2 className="H-turn">Household Products</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    String
                </p>
                <p>
                    Cats love string and things on strings, but sometimes they accidentally eat the string.  This is a common reason for cats to end up in emergency surgery.  If you're going to allow your cat to responsibly play with string, make sure to supervise this closely. Never leave loose string, dental floss, or similar linear objects around your cat.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Household chemicals and cleaners
                </p>
                <p>
                    Don't give your cat access to household cleaning products or other chemicals.  Cats love to explore and taste new things, so keep dangerous household cleaning products and other chemicals locked away from you cat.
                </p>
                <div className="Center">
                    <img src={cat25} alt="Cat" />
                </div>
            </div>
        );
    }

}
