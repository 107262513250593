import React, { Component } from "react";
import "./Header.css";
import dog from './images/dog.png'
import cat from './images/cat.png'
import maui from './images/maui.png'

export default class HeaderCats extends Component {
    render() {
        return (
            <div className="H-width">
                <svg x={0} y={0} enableBackground={"new"} viewBox={"0 0 330 100"}>
                  <circle fill="none" stroke="#000" strokeWidth={4} cx={50} cy={50} r={46} style={{opacity:0.7}}/>
                  <rect fill="#fffefa" stroke="#fffefa" strokeWidth={3} x={9} y={9} width={10} height={10} >
                    <animateTransform
                      attributeName="transform"
                      dur="20s"
                      type="rotate"
                      from="0 50 50"
                      to="360 50 50"
                      repeatCount="indefinite" />
                  </rect>
                  <a xlinkHref="/dogs">
                    <g><text x={37} y={85} fontFamily="Material Icons" style={{opacity:0.8}}>touch_app</text></g>
                    <image style={{cursor: "pointer", opacity: 0.15}} xlinkHref={dog} x={13} y={13} height={74} width={74}/>
                  </a>
                  <circle fill="none" stroke="#000" strokeWidth={4} cx={165} cy={50} r={46} style={{opacity:0.7}}/>
                  <rect fill="#fffefa" stroke="#fffefa" strokeWidth={3} x={124} y={9} width={10} height={10} >
                    <animateTransform
                      attributeName="transform"
                      dur="20s"
                      type="rotate"
                      from="0 165 50"
                      to="360 165 50"
                      repeatCount="indefinite" />
                  </rect>
                  <a xlinkHref="/cats">
                    <image style={{cursor: "pointer"}} xlinkHref={cat} x={128} y={13} height={74} width={74}/>
                  </a>
                  <circle fill="none" stroke="#000" strokeWidth={4} cx={280} cy={50} r={46} style={{opacity:0.7}}/>
                  <rect fill="#fffefa" stroke="#fffefa" strokeWidth={3} x={239} y={9} width={10} height={10} >
                    <animateTransform
                      attributeName="transform"
                      dur="20s"
                      type="rotate"
                      from="0 280 50"
                      to="360 280 50"
                      repeatCount="indefinite" />
                  </rect>
                    <image xlinkHref={maui} x={243} y={13} height={74} width={74}/>
                </svg>
            </div>
        );
    }

}
