import React, { Component } from 'react';
import "../Content.css";
import dasch from '../../images/dogs/dasch.png';
import dog8 from '../../images/dogs/dog008.png';
import dog14 from '../../images/dogs/dog014.png';
import dog15 from '../../images/dogs/dog015.png';
import borderCollie from '../../images/dogs/borderCollie.png';
import puppyBeagle from '../../images/dogs/investDog.png';
import dogPink from '../../images/dogs/dogPink.png';

export default class CoreVaccines extends Component {
    render() {
        return (
            <div className="C-width">
                <h1>Vaccines</h1>
                <p>
                    Vaccination is "one of the safest and most cost-effective means of infectious disease prevention" &mdash; <a target="_blank" rel="noopener noreferrer" href='https://www.aaha.org/public_documents/guidelines/vaccination_recommendation_for_general_practice_table.pdf'><em>2017 AAHA Canine Vaccination Guidelines</em></a>
                </p>
                <h2 className="H-turn">Main Points</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Canine distemper virus, adenovirus-2, and parvovirus, and leptospirosis vaccines are generally considered core for all dogs in Hawaii.
                </p>
                <p>
                    Canine distemper virus, adenovirus-2, and parvovirus vaccines are often combined into one vaccine. This combo vaccine may or may not contain vaccines for parainfluenza virus and leptospirosis.
                    Dogs that are frequently exposed to streams, lakes or any other outdoor water, should be vaccinated against leptospirosis.  Your veterinarian is the best authority on what vaccines should be considered core for your individual dog.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Vaccine reactions are rare, but pet parents should be aware of this risk.
                </p>
                <p>
                    With any vaccination, there is a very small risk of an unwanted vaccine reaction.  We hope to get some level of immune response from the administered vaccination, so it is likely that your dog will act
                    a bit out of it (sore, tired, less playful) and may even have a low-grade fever after receiving vaccinations.  Mild GI upset, vomiting, and diarrhea are the most commonly encountered vaccine reactions.  If your dog develops facial swelling, collapses, or has any difficulty breathing, your dog may be experiencing a vaccine reaction and should see a veterinarian immediately.  Again, these reactions
                    are very rare, and your veterinarian considers these risks when they make recommendations about which vaccines are core for your dog.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={dasch} alt="Dog" />
                </div>
                <h2 className="H-turn Right">Combo Vaccine</h2>
                <p  className="P-top" style={{fontWeight: 'bold'}}>
                    A combination vaccine often referred to as DA<sub>2</sub>P, DA<sub>2</sub>PP, or DA<sub>2</sub>PP + Lepto should be administered several times a few weeks apart for puppies and then yearly for adult dogs.
                </p>
                <p>
                    This combination vaccine protects against distemper virus, adenovirus, and parvovirus. The addition of the second 'P' indicates that parainfluenza virus is also included. Some combination vaccines also protect against several serovars of Leptospira.  During puppyhood, the timing of vaccination is important.  This is due to maternal antibodies which are absorbed from the mother's milk soon after the puppy is born.  These maternal antibodies are a good thing, as they help protect your puppy against these common infectious threats.  It is important that the final puppy vaccines are given around 16 weeks of age to ensure that your puppy can mount a strong immune response.  Some combination vaccines can be given once every three years, so talk to your veterinarian about which combination vaccine is most appropriate for your dog.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={borderCollie} alt="Dog" />
                </div>
                <h2 className="H-turn">Leptospirosis</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Leptospira is a bacteria commonly found in outdoor water sources and can cause kidney and liver damage in dogs.
                </p>
                <p>
                    Leptospira is very common in Hawaii and can be found in puddles and streams.
                    Rodents and other animals spreads the bacteria through their urine.
                    Even primarily indoor dogs may benefit from vaccination, since Leptospira can be found in city puddles, yards, or anywhere else rodents and wildlife may have been.
                </p>
                <p>
                    This vaccine must be boostered every year even if it was initially given as part of a 3-year combination vaccine.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={dog14} alt="Dog" />
                </div>
                <h2 className="H-turn Right">Bordetella</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Bordetella is a bacteria and one of several infectious agents that causes cough in dogs.
                </p>
                <p>
                    Dogs that are exposed to other dogs through travel, boarding, or daycare benefit most from vaccination against Bordetella.  Many boarding facilities require this vaccination.
                </p>
                <p>
                    This vaccine is generally administered orally and should be boostered every year.  An intranasal vaccine is available, but must be boostered every 6 months.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={dog8} alt="Dog" />
                </div>
                <h2 className="H-turn">Rabies</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Rabies vaccination administered or supervised by a veterinarian is required by law in most states.
                </p>
                <p>
                    Rabies vaccination is not required by the state of Hawaii.  If your dog plans on traveling outside of Hawaii, please let your veterinarian know well ahead of time.  Most states require your dog be vaccinated against rabies before entry.
                    Blood testing (FAVN testing) is required for dogs entering Hawaii to show that their immune system has responded to the rabies vaccine.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={dog15} alt="Dog" />
                </div>
                <h2 className="H-turn Right">Lyme Disease</h2>
                <p  className="P-top" style={{fontWeight: 'bold'}}>
                    A vaccine that protects against canine Lyme disease is available and may benefit dogs with more risk of exposure.
                </p>
                <p>
                    Certain dogs with an increased risk of tick exposure, especially in the Northeast and Midwest United States where Lyme disease is more prevalent, may benefit from vaccination.  Talk to your veterinarian about vaccinating your dog against Lyme disease if you feel your dog may be at an increased risk.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={puppyBeagle} alt="Dog" />
                </div>
                <h2 className="H-turn">Influenza</h2>
                <p  className="P-top" style={{fontWeight: 'bold'}}>
                    Dogs with an increased risk of exposure to canine influenza virus may benefit from vaccination against this highly contagious virus.
                </p>
                <p>
                    Dogs that frequently interact with or are exposed to other dogs at dog parks, boarding kennels, or events would likely benefit from vaccination against canine influenza virus. Many boarding kennels and daycares require this vaccination. Canine influenza virus is not a threat to humans, but it is highly contagious to other dogs.  Two serotypes exist, and there are vaccines that provide some protection against both. Talk to your veterinarian to determine if vaccinating against canine influenza virus is right for your dog.
                </p>
                <div className="Center">
                    <img src={dogPink} alt="Dog" />
                </div>
            </div>
        );
    }

}
