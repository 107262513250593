import React, { Component } from 'react';
import "../Content.css";
import beagle from '../../images/dogs/beagle.png';

export default class SpayAndNeuter extends Component {
    render() {
        return (
            <div className="C-width">
                <h1>Spay & Neuter</h1>
                <p>
                     "The American Animal Hospital Association supports the concept of neutering cats and dogs as young as eight weeks of age in order to help reduce the overpopulation issues that can be present in companion animals." &mdash; <a target="_blank" rel="noopener noreferrer" href='https://www.aaha.org/professional/resources/pediatric_neutering.aspx'><em>American Animal Hospital Association</em></a>
                </p>
                <h2 className="H-turn">Main Points</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Cancer and other health problems are more common in dogs that have not been spayed or neutered.
                </p>
                <p>
                    Pyometra, a life-threatening bacterial infection of the uterus that usually requires surgery to treat, can be entirely prevented by spaying your female dog early.  Additionally, the risk of breast cancer is greatly reduced by spaying before the first heat cycle.
                    In males, benign prostatic hyperplasia which can cause difficulty urinating and defecating can be prevented by neutering early.  The risk of testicular cancer is eliminated by neutering male dogs early.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Behavioral problems can be avoided by spaying or neutering early.
                </p>
                <p>
                    Early neutering can prevent male dogs from spraying urine to mark the furniture around you home.  Once this unfortunate habit has been formed, it may not be possible to break it, even with neutering. Neutering may also prevent unwanted aggressive behavior.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Pet overpopulation is a crisis that leaves many unwanted dogs in shelters every year.
                </p>
                <p>
                    Don't let your dog accidently worsen this problem.  Intact dogs, or dogs that have not been spayed or neutered, are very good at finding each other. The mating event only takes a moment. Accidental pregnancies are frequent in veterinary medicine, can endanger the health of your dog, and contribute to the overpopulation problem.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Talk to your veterinarian about when you should spay or neuter your puppy.
                </p>
                <p>
                    Your veterinarian can give you the most informed advice on when it is most appropriate to spay or neuter your puppy.  There may be some advantage to waiting until a certain age before neutering large-breed dogs.
                </p>
                <div className="Center">
                    <img src={beagle} alt="Dog" />
                </div>
            </div>
        );
    }   
    
}