import React, { Component } from 'react';
import "../Content.css";
import cat15 from '../../images/cats/cat015.png';
import cat17 from '../../images/cats/cat017.png';
import cat18 from '../../images/cats/cat018.png';
import cat19 from '../../images/cats/cat019.png';

export default class CatBehavior extends Component {
    render() {
        return (
            <div className="C-width">
                <h1>Behavior</h1>
                <p>
                    "They are friendly to people and yet the individual, in Rudyard Kipling's phrase, 'walks by himself', readily accepting the comforts of the human home and yet behaving as though his independence were
                    total." &mdash; <a target="_blank" rel="noopener noreferrer" href='https://www.amazon.com/gp/product/1107025028/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=1107025028&linkId=93980bd3a3d4836c008773efefda1242'><em>The Domestic Cat: The Biology of its Behavior</em></a>
                </p>
                <h2 className="H-turn">Key Concepts</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Cats can be trained.
                </p>
                <p>
                    Spending time each day training your cat can be mentally stimulating for both of you and can result in a very well-trained cat.
                    Figure out what motivates your cat, be consistent, and break down your training goals into achievable steps.
                    Using <a target="_blank" rel="noopener noreferrer" href='https://www.amazon.com/gp/product/B010A4VAHW/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B010A4VAHW&linkId=4b04ab8df740b71702e99da24d6d8a70'>a clicker</a> as a bridging stimulus can help you be more immediate with your communication.
                    Check out <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=aMGh7goLNFM">this video</a> about teaching a cat to sit.
                    Training sessions should be fun for everyone involved, so if you or your cat get frustrated at any point, take a break.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Positive reinforcement is the most efficient and effective way to raise a well-adapted, well-trained cat.
                </p>
                <p>
                    There is a huge body of evidence in human and animal psychology that shows the power of positive reinforcement.  It works. It works well.  And, when used properly, it ensures that you will raise a well-adapted cat.  By committing to positive reinforcement, you can be sure that your cat is eager to learn and grow, and you can avoid reinforcing the fear pathways that are associated with punishment and often lead to problem behaviors and an emotionally-confused cat.  To be clear, we believe you should never punish your cat for any reason.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={cat15} alt="Cat" />
                </div>
                <p style={{fontWeight: 'bold'}}>
                    Always keep the litter box clean.
                </p>
                <p>
                    Going outside the box is a very common behavioral complaint of cat parents.  You can help ensure this doesn't become a bad habit by cleaning the litter box daily.  In households with more than one cat, you should try to have one more litter box than you have cats.  We understand how difficult this can be, but litter box possessiveness can be a serious problem.  Don't let your cats' litter box feud turn into a bad habit of peeing on the carpet.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Prevent your cat from becoming bored.
                </p>
                <p>
                    Bored cats can become depressed and overweight which will lead to other health problems.  Take some time every day to engage in playful activities with your cat.  Having a <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/search?ie=UTF8&tag=dogscatsmon0a-20&linkCode=ur2&linkId=e9e524ddc2eefb9c4d63f3a32a3a5a6e&camp=1789&creative=9325&index=pets&keywords=cat toy">variety of toys</a> can help keep your cat mentally and physically active.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={cat17} alt="Cat" />
                </div>
                <p style={{fontWeight: 'bold'}}>
                    Give your cat <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/search?ie=UTF8&tag=dogscatsmon0a-20&linkCode=ur2&linkId=aa2d9e7e19c957cac5b3784a6314899d&camp=1789&creative=9325&index=pets&keywords=scratching post">something good to scratch</a>.
                </p>
                <p>
                    Cats love to scratch because it feels good, stretches them out, and marks safe places.  Once cats are in the habit of scratching your upholstery,
                    drapes, or walls, it can be challenging to retrain them to scratch a more appropriate object.  Make sure your cat always has access to a good scratching
                    post that lets them fully extend their body.  Add other scratch-friendly cat things around your home.  If your cat has developed the habit of scratching an
                    inappropriate object, then the only good option is to remove the object or make it inaccessible to your cat.
                    Removing the claws of your cat is almost never a good option, since it requires removing the digits at the last joint.
                    This can be extremely upsetting to a cat and often leads to more serious behavioral problems.  It is also dangerous for any cat that spends
                    time outdoors to be without claws; your cat uses them to escape up trees and fight danger.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Vertical space and places to hide are important features of a cat-friendly home.
                </p>
                <p>
                    Try adding some <a target="_blank" rel="noopener noreferrer" href='https://www.amazon.com/gp/product/B078KHG5Q6/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B078KHG5Q6&linkId=21fd151027052a9ab6231ac9df4272b3'>floating shelves</a> to give your cat access to higher spaces in your home.  Keep safe hiding spaces, such as those found under beds or couches, open so that your cat can take advantage of these retreats.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={cat18} alt="Cat" />
                </div>
                <h2 className="H-turn Right">Additional Resources</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Books
                </p>
                <p>
                    <a target="_blank" rel="noopener noreferrer" href='https://www.amazon.com/gp/product/0143119796/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=0143119796&linkId=c1bd8043a677693a6e3cbef4fc0917ec'><em>Think Like a Cat: How to Raise a Well-Adjusted Cat&mdash;Not a Sour Puss</em></a> by Pam Johnson-Bennett is an approachable book for all skill levels.  It is a well-rounded book and a solid starting point to understand cat behavior.  It also contains additional information on general cat care best practices.
                </p>
                <p>
                    <a target="_blank" rel="noopener noreferrer" href='https://www.amazon.com/gp/product/1107025028/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=1107025028&linkId=93980bd3a3d4836c008773efefda1242'><em>The Domestic Cat: The Biology of its Behaviour</em></a> is a more thorough picture of the behavior of cats with good historical and social perspective.  It may feel more like a textbook to some cat parents, but we would highly recommend it to anyone who wants a deeper understanding of all things cat.
                </p>
                <p>
                    <a target="_blank" rel="noopener noreferrer" href='https://www.amazon.com/gp/product/0142004758/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=0142004758&linkId=1986a7441977903cca719ebf7cdc30cd'><em>Cat vs. Cat: Keeping Peace When You Have More Than One Cat</em></a> by Pam Johnson-Bennett is a very approachable read that outlines many of the challenges multi-cat households can face.  Pam Johnson-Bennett offers practical and proven solutions, and this book would be a good addition for anyone who has, or will soon have, more than one cat.
                </p>
                <p>
                    <a target="_blank" rel="noopener noreferrer" href='https://www.amazon.com/gp/product/0465064965/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=0465064965&linkId=09118ee096e12b1d53b9ed7ef6cadcd2'><em>Cat Sense: How the New Feline Science Can Make You a Better Friend to Your Pet</em></a> by John Bradshaw is a very approachable book for any cat parent that wants a better understanding of why cats act the way they do.
                </p>
                <p>
                    <a target="_blank" rel="noopener noreferrer" href='https://www.amazon.com/gp/product/0743297776/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=0743297776&linkId=bb40ae6f875ae0281c1913df21eb2317'><em>Reaching the Animal Mind: Clicker Training and What It Teaches Us About All Animals</em></a> by the behavioral biologist Karen Pryor is the best resource we can direct you to for learning the benefits of clicker training.  Dr. Karen Pryor is the founder of clicker training and still a leading figure in the field of animal behavior.  In about 250 pages she will give you many of the tools necessary to communicate with all animals, not just your cat.
                </p>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Websites
                </p>
                <p>
                    <a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/cat.school/?hl=en'><em>Cat School (on Instagram)</em></a> is full of inspiration and instruction for training your feline friend.
                </p>
                <p>
                    <a target="_blank" rel="noopener noreferrer" href='https://indoorpet.osu.edu/cats'><em>The Indoor Pet Initiative</em></a> from The Ohio State University is a trusted web resource for curious cat parents.
                </p>
                <div className="Center">
                    <img src={cat19} alt="Cat" />
                </div>
            </div>
        );
    }

}
