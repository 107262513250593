import React, { Component } from 'react';
import "../Content.css";
import dog14 from '../../images/dogs/dog014.png';
import dog13 from '../../images/dogs/dog013.png';
import dog10 from '../../images/dogs/dog010.png';
import dog23 from '../../images/dogs/dog023.png';

export default class Behavior extends Component {
    render() {
        return (
            <div className="C-width">
                <h1>Behavior</h1>
                <p>"Whether or not we're aware of it, every time we interact with
                an animal, we're learning something, and the animal is learning
                something, too." &mdash; Dr. Sophia Yin, <a target="_blank" rel="noopener noreferrer" href='https://www.amazon.com/gp/product/0793806445/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=0793806445&linkId=0f090cc32fe2c6058d69b6248da564d3'><em>How to Behave so Your Dog Behaves</em></a>
                </p>
                <h2 className="H-turn">Key Concepts</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Positive reinforcement is the most efficient and effective way to raise a well-adapted, well-trained dog.
                </p>
                <p>
                    There is a huge body of evidence in human and animal psychology that shows the power of positive reinforcement.  It works. It works well.  And, when used properly, it ensures that you will raise a well-adapted dog.  By committing to positive reinforcement, you can be sure that your dog is eager to learn and grow, and you can avoid reinforcing the fear pathways that are associated with punishment and often lead to problem behaviors and an emotionally-confused dog.  To be clear, we believe you should never punish your dog for any reason.  We have successfully trained many dogs, even very troubled dogs, by carefully controlling their environment and only rewarding desirable behaviors.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Be consistent and predictable.
                </p>
                <p>
                    Consistency is key. Get all the relevant humans on board with the training process, and make sure everyone understands the goals and methods.  If you are training your dog to sit&ndash;instead of jumping all over you&ndash;when you come in the front door, your training can be quickly undone by a friend or family member that praises your dog for jumping all over them on entry.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Be patient.
                </p>
                <p>
                    Your dog is reading your body language and will be confused by any display of your frustration.  Shaping your dog's behavior often takes breaking it down into small achievable steps that cannot all be learned in the same training session.  There will be challenges along the training journey, but you will be a more effective trainer if you remain calm through moments of undesirable behavior. Training sessions should be fun for everyone involved, so take a break if you or your dog lose patience at any point.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={dog14} alt="Dog" />
                </div>
                <p style={{fontWeight: 'bold'}}>
                    Use every opportunity to shape desirable behaviors.
                </p>
                <p>
                    Every interaction with our dog is an opportunity to shape your dog's behavior.  Your training sessions don't always have to be formal blocks of time. Embrace the mindset that every time you feed your dog, put it on a leash, or let it out into the backyard, you have an opportunity to reinforce a desired behavior.  Once your dog knows how to sit, ask it to sit and wait patiently before you release it to enjoy its dinner.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Appropriately control the environment to give your dog the best opportunity to succeed.
                </p>
                <p>
                    Much of your training should be started in a safe, controlled space that your dog is familiar with.
                    Teaching your dog to reliably sit in a park full of squirrels, birds, people, smells, and
                    noises may be an eventual training goal, but you will need to start with a smaller goal.  Start in a safe, controlled environment, like a familiar room.
                    Next move to a familiar outdoor space, like your backyard. Then move to a quiet dog-friendly park at a time when it isn't busy.
                    If at any point you seem to be making negative progress, you may be trying to progress too quickly.
                    Let your dog set the pace, and don't be afraid to go back a few steps anytime you are training something new
                    or a new environmental context isn't working out.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Alternative desired behaviors can replace problem behaviors.
                </p>
                <p>
                    Operant counterconditioning is training an alternative behavior to replace an undesired behavior.  For example, if your
                    dog jumps all over any new human friends, you could teach your dog that it is more appropriate to sit patiently in this situation.
                    First, it would be best if your dog had a very strong 'sit' that could be performed on your cue. Make sure you start in a safe, controlled environment with few distractions, and then slowly, at your dog's pace, progress to more challenging situations or social contexts.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={dog13} alt="Dog" />
                </div>
                <p style={{fontWeight: 'bold'}}>
                    Use a reward that motivates your dog.
                </p>
                <p>
                    Some dogs are motivated by any food, but some only respond to certain, more desirable, food or treats.
                    Other dogs prefer a certain toy, praise, pets, or attention.  Learn what motivates your dog the most.
                    Intermittent positive reinforcement, where you only reward the desired behavior some of the time, is a
                    stronger motivator than reinforcing the behavior every single time.  As you and your dog progress in your training,
                    you will learn to intermittently reinforce the desired behavior.  Since timing is important, and you are likely to miss or
                    mistime your rewards when working on a new behavior, it is best to start by trying to reinforce the desired behavior every
                    time.  <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B00JAEKBGA/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B00JAEKBGA&linkId=0e26c007133930b355c2bf19e7702666">Merrick Power Bites</a> have
                    been a favorite of many of our dogs and are an appropriately sized training treat.
                    Remember that treats should be no more than 10% of the daily calories consumed by your dog.  For this reason, pet parents with food-motivated dogs can use some of their dog's normal meals, kibble by kibble, as a training reward.
                </p>
                <p  style={{fontWeight: 'bold'}}>
                    Timing is everything.
                </p>
                <p>
                    It is important to be immediate with your feedback when you are trying to communicate with your dog.  For this
                    reason, <a target="_blank" rel="noopener noreferrer" href='https://www.amazon.com/gp/product/B010A4VAHW/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B010A4VAHW&linkId=4b04ab8df740b71702e99da24d6d8a70'>a clicker</a> is
                    helpful when trying to time your communication with your dog.
                    Using a click as a bridging stimulus allows you to properly time the click with the desired behavior and gives you more time to deliver the reward.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={dog10} alt="Dog" />
                </div>
                <h2 className="H-turn Right">Additional Resources</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Books
                </p>
                <p>
                    <a target="_blank" rel="noopener noreferrer" href='https://www.amazon.com/gp/product/0793806445/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=0793806445&linkId=0f090cc32fe2c6058d69b6248da564d3'><em>How to Behave so Your Dog Behaves</em></a> by Dr. Sophia Yin is an approachable book for all skill levels.  It is full of invaluable tools to help train your dog.  This is the first book we recommend to anyone with an interest in dog training.
                </p>
                <p>
                    <a target="_blank" rel="noopener noreferrer" href='https://www.amazon.com/gp/product/0743297776/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=0743297776&linkId=bb40ae6f875ae0281c1913df21eb2317'><em>Reaching the Animal Mind: Clicker Training and What It Teaches Us About All Animals</em></a> by the behavioral biologist Karen Pryor is the best resource we can direct you to for learning the benefits of clicker training.  Dr. Karen Pryor is the founder of clicker training and still a leading figure in the field of animal behavior.  In about 250 pages she will give you many of the tools necessary to communicate with all animals, not just your dog.
                </p>
                <div className="Center">
                    <img src={dog23} alt="Dog" />
                </div>
            </div>
        );
    }

}
