import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Content.css";

export default class ContentHome extends Component {
    render() {
        return (
            <div className="C-width">
                <h2 className="H-turn">DOGS</h2>
                <p className="P-top">
                    Information for new dog parents, old dog parents, and soon-to-be dog parents—to help you build the best relationship with your dog
                </p>
                    <div className="Center" style={{paddingTop: 0}}>
                    <Link className="Button-blue" to="/dogs">Learn more</Link>
                    </div>



                <h2 className="H-turn Right">CATS</h2>
                <p className="P-top">
                    Pro tips for anyone thinking about getting a cat, in the process of getting a cat, or already surrounded by cats
                </p>
                    <div className="Center" style={{paddingTop: 0}}>
                    <Link className="Button-blue" to="/cats">Learn more</Link>
                    </div>

                <h2 className="H-turn">AUSTIN</h2>
                <p className="P-top">
                    Find a local vet. Schedule your visit today for a happier, healthier pet.
                </p>
                    <div className="Center" style={{paddingTop: 0}}>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/search/veterianrian/@30.3092075,-97.7778216,13z" className="Button-blue">Veterinary Clinics in Austin, Texas</a>
                    </div>

            </div>
        );
    }

}
