import React, { Component } from 'react';
import "../Content.css";
import goldenHappy from '../../images/dogs/goldenHappy.png';
import dogCouch from '../../images/dogs/dogCouch.png';
import whycutcosts from '../../images/dogs/whycutcosts.png';

export default class DentalCare extends Component {
    render() {
        return (
            <div className="C-width">
                <h1>Dental Care</h1>
                <p>
                    "Periodontal disease is the most common clinical condition affecting adult pets, despite the fact that it is almost entirely preventable." &mdash; <a target="_blank" rel="noopener noreferrer" href='https://www.avdc.org/ownersinfo.html'><em>American Veterinary Dental College</em></a>
                </p>
                <h2 className="H-turn">Main Points</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Dental diseases can cause bad breath, pain, and more serious systemic disease in your dog.
                </p>
                <p>
                    Neglecting dental care in your pet can cause bad breath, a painful mouth, and even heart, kidney, or liver problems.  Yearly dental cleanings, performed under anesthesia at a veterinary clinic, can help your dog avoid more serious diseases.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    You can help prevent dental diseases with daily teeth brushing, dental chews, water additives, and teeth-friendly toys.
                </p>
                <p>
                    Additional at-home tips and tools to help you keep your dog's smile healthy are provided below.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Talk to your veterinarian about your dog's dental care and when to schedule your dog's next dental cleaning.
                </p>
                <p>
                    One annual dental cleaning is recommended for most dogs, but every dog is different and some would benefit from more frequent cleanings. Diet, lifestyle, and genetics all contribute to the dental health of your dog. Your veterinarian can make the most informed recommendation on how to provide the best dental care for your dog.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={goldenHappy} alt="Dog" />
                </div>
                <h2 className="H-turn Right">At Home</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Daily Brushing
                </p>
                <p>
                    Daily brushing is a great way for pet parents to slow the buildup of bacterial plaque on their dog's teeth.
                    It's important to take the time to train your dog to accept and enjoy teeth brushing.
                    This <a target="_blank"  rel="noopener noreferrer" href="https://www.youtube.com/watch?v=W0Tes-3CcbU">YouTube video</a> is an
                    introduction to dog teeth brushing.  It is often necessary to first train your dog to accept a
                    gentle teeth brushing with your finger, a piece of gauze wrapped around your finger, or a fingerbrush like the one
                    included in <a target="_blank"  rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B004ULZ2PI/ref=as_li_qf_asin_il_tl?ie=UTF8&tag=dogscatsmon0a-20&creative=9325&linkCode=as2&creativeASIN=B004ULZ2PI&linkId=d4b6714473d66ac3d735f4fb909e66c2">this kit</a>.
                    It is important to use a <a target="_blank"  rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B004ULYZ5Q/ref=as_li_qf_asin_il_tl?ie=UTF8&tag=dogscatsmon0a-20&creative=9325&linkCode=as2&creativeASIN=B004ULYZ5Q&linkId=e442a4d69636f7769f104ad0a7b03237">dog toothpaste</a> which will not contain fluoride like human toothpaste.
                    Although it may be the single best thing you could do for your dog's oral health, daily brushing is not appropriate for every dog.  If it isn't enjoyable for you and your dog, we don't recommend it.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Dental Chews
                </p>
                <p>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B001P3PR5Y/ref=as_li_qf_asin_il_tl?ie=UTF8&tag=dogscatsmon0a-20&creative=9325&linkCode=as2&creativeASIN=B001P3PR5Y&linkId=49097f45c520d6d37fd475a74a01d595">Dental chews</a> that are stamped with the Veterinary Oral Health Council's stamp of approval can help keep your dog's teeth healthy. They should be chewed daily to be effective, and if your dog scarfs them down without chewing, they will not do much for the teeth.  If your dog chews rawhide type treats more politely, <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B001P3NU30/ref=as_li_qf_asin_il_tl?ie=UTF8&tag=dogscatsmon0a-20&creative=9325&linkCode=as2&creativeASIN=B001P3NU30&linkId=57134635a89b0630d8430f7ce2cb0c86">these rawhide dental chews</a> may be a better option.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Water Additives
                </p>
                <p>
                    There are a lot of water additives that claim to improve your dog's oral health.  <a target="_blank" rel="noopener noreferrer" href="https://www.healthymouth.com/Articles.asp?ID=246">HealthyMouth</a> water additive is the only one that has the
                    Veterinary Oral Health Council's stamp of approval. <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/search?ie=UTF8&tag=dogscatsmon0a-20&linkCode=ur2&linkId=2567c997530e98e065caaaf8f2fe79b2&camp=1789&creative=9325&index=pets&keywords=water additive">Other products</a> are out there and work well for some dogs.  It's important to keep in mind that these products are not as effective as daily brushing and are not a replacement for regular dental cleanings from a veterinarian.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Chew Toys
                </p>
                <p>
                    Some toys, including tennis balls, can cause abrasive wear with repetitive chewing and damage your dog's teeth. Try replacing tennis balls with <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B000F4AVPA/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B000F4AVPA&linkId=5ffc6633ea0e99caee414d4ee2384d41">rubber ones</a>.  Natural bones and antlers can be dangerous or hard enough to break your dog's teeth.  We recommend using hard commercial chew toys like this <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B0010P0YSW/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B0010P0YSW&linkId=be40332c9d8be2c0543fe8893f13f7a1">Nylabone</a> to help protect your dog's dental health.  Again, this is not a substitution for regular dental cleanings from a veterinarian.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={dogCouch} alt="Dog" />
                </div>
                <h2 className="H-turn">At your Vet</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Your veterinarian is only able to properly evaluate your dog's teeth while your dog is under anesthesia.
                </p>
                <p>
                    Your veterinarian will often get a brief look in your dog's mouth during a physical exam which can give some information about your dog's oral health. Since most dental disease hides below the gumline, it is important to schedule regular prophylactic dental cleanings with your veterinarian. While your dog is under anesthesia, your veterinarian will clean beneath the gumline and take dental radiographs if significant periodontal disease is suspected. For this reason, anesthesia-free dentals are not recommended.  The <a target="_blank" rel="noopener noreferrer" href="http://avdc.org/AFD/">American Veterinary Dental College</a> has more information to educate dog parents on the risks associated anesthesia-free dentistry.
                </p>
                <div className="Center">
                    <a target="_blank" rel="noopener noreferrer" href="http://avdc.org/AFD/"><img src={whycutcosts} alt="AVDC dog and woman" /></a>
                </div>
            </div>
        );
    }

}
