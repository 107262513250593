import React, { Component } from 'react';
import "../Content.css";
import dog21 from '../../images/dogs/dog021.png';
import dog16 from '../../images/dogs/dog016.png';
import dog17 from '../../images/dogs/dog017.png';
import dogOrangeCar from '../../images/dogs/dogOrangeCar.png';

export default class Toxins extends Component {
    render() {
        return (
            <div className="C-width">
                <h1>Toxins</h1>
                <p>
                    This page contains some of the most common toxins that threaten the health of your dog.  It is by no means a complete list, and even non-toxic things can become toxic if your dog consumes enough of them. The <a target="_blank" rel="noopener noreferrer" href="http://www.petpoisonhelpline.com/">Pet Poison Helpline</a> is a great resource with a lot of information.  If you suspect that your dog is experiencing a toxicity, do not hesitate to contact your veterinarian or bring your dog to the closest emergency veterinary clinic immediately.
                </p>
                <h2 className="H-turn">Foods</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Chocolate
                </p>
                <p>
                    Theobromine and caffeine found in cocoa and cocoa-based products are extremely toxic to dogs.  Usually, darker chocolates contain more of these toxins, but, in great enough quantities, milk chocolate can be just as harmful. Depending on the amount ingested, symptoms range from vomiting and diarrhea to abnormal heart rhythms and seizures.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Xylitol
                </p>
                <p>
                    Xylitol is an artificial sweetener found in many human products including chewing gum and some peanut butter products.  Dogs and cats cannot tell the difference between this and regular sugar, which causes their blood glucose levels to drop to dangerous levels. Xylitol ingestion can also cause liver failure.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Macadamia Nuts
                </p>
                <p>
                    Especially dangerous if covered in chocolate, macadamia nuts can cause a range of symptoms in dogs ranging from vomiting to tremors and inability to walk.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Grapes
                </p>
                <p>
                    Grapes can cause acute kidney failure in dogs.  Remember that raisins are dried grapes, and when covered in chocolate, they are especially dangerous.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Garlic
                </p>
                <p>
                    Garlic causes damage to the red blood cells of dogs, reducing your dog's ability to get oxygen to the tissues.  Onions and other close relatives of garlic should also be avoided.  These are dangerous because they are often found in broths and other human-safe foods.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={dog21} alt="Dog" />
                </div>
                <h2 className="H-turn Right">Plants & Mushrooms</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Sago Palm
                </p>
                <p>
                    Just a small amount of this common ornamental plant can cause serious liver disease in your dog.  Do not give your dog any access to sago palms or relatives of this plant.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Yew
                </p>
                <p>
                    Another common ornamental, the yew, can cause seizures and death in dogs.  Do not use this plant where your dog might have access to it.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Azalea or Rhododendron
                </p>
                <p>
                    There are many varieties of azaleas and rhododendrons and several are extremely toxic to dogs.  Take care to keep your dog away from this threat.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Wild Mushrooms
                </p>
                <p>
                    Wild mushrooms can be a tempting snack for your curious dog, but many are toxic.  Symptoms range from vomiting and diarrhea to liver failure and seizures depending on the species of mushroom.  Be especially careful after periods of wet weather.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={dog16} alt="Dog" />
                </div>
                <h2 className="H-turn">Household Products</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Socks
                </p>
                <p>
                    Socks, along with other cloth objects, are a common reason for dogs to end up in emergency surgery.  Socks can get stuck in the stomach or elsewhere in  your dog's gastrointestinal tract.  If there is a chance that your dog will try to eat a sock, you can prevent this by limiting your dog's access to socks and similar items.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Pennies
                </p>
                <p>
                    Pennies, and other small metallic objects including jewelry, contain zinc which is extremely toxic to dogs.  Zinc will quickly destroy your dogs red blood cells and reduce your dog's ability to deliver oxygen to the tissues.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Household Chemicals and Cleaners
                </p>
                <p>
                    Don't give your dog access to household cleaning products or other chemicals.  Dogs like to taste things first and ask questions later, so keep dangerous household cleaning products and other chemicals locked away from your dog.
                </p>
                <div className="Center">
                    <img src={dog17} alt="Dog" />
                </div>
                <h2 className="H-turn Right">Human Drugs</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Marijuana
                </p>
                <p>
                    Recreational drugs used by humans have a totally different effect on our four-legged family members.  Marijuana and synthetic cannabinoids cause a range of concerning symptoms in dogs and exposure usually requires emergency care from your veterinarian.  Be careful not to allow any accidental ingestion.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Alcohol
                </p>
                <p>
                    Adult beverages are for human adults, not dogs.  Even a small amount of alcohol can cause lethargy, stumbling, and disorientation in dogs.  Do not share alcohol with four-legged friends.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Human pain medications
                </p>
                <p>
                    Over-the-counter pain medications intended for humans such as acetaminophen (Tylenol), aspirin, and ibuprofen (Advil) can have dangerous consequences if used in dogs. They can damage your dog’s blood cells, kidneys, liver, and stomach and cause a range of signs from depression to bleeding disorders or even death.  Do not give your dog human pain medications. If you suspect your dog is in pain, your veterinarian can prescribe dog-appropriate pain medications.
                </p>
                <div className="Center">
                    <img src={dogOrangeCar} alt="Dog" />
                </div>
            </div>
        );
    }   
    
}