import React, { Component } from 'react';
import "../Content.css";
import cat8 from '../../images/cats/cat008.png';

export default class CatSpay extends Component {
    render() {
        return (
            <div className="C-width">
                <h1>Spay & Neuter</h1>
                <p>
                     "The American Animal Hospital Association supports the concept of neutering cats and dogs as young as eight weeks of age in order to help reduce the overpopulation issues that can be present in companion animals." &mdash; <a target="_blank" rel="noopener noreferrer" href='https://www.aaha.org/professional/resources/pediatric_neutering.aspx'><em>American Animal Hospital Association</em></a>
                </p>
                <h2 className="H-turn">Main Points</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Cancer and other health problems are more common in cats that have not been spayed or neutered.
                </p>
                <p>
                    Pyometra, a life-threatening bacterial infection of the uterus that usually requires surgery to treat, can be entirely prevented by spaying your female cat early. Additionally, the risk of breast cancer is reduced by spaying early.
                    In males, many diseases that are spread by fighting can be prevented by neutering early.  The risk of testicular cancer and prostate disease is higher in male cats that have not been neutered.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Behavioral problems can be avoided by spaying or neutering early.
                </p>
                <p>
                    Early neutering can prevent cats from urine spraying and fighting with other cats.  Once cats have started urine spraying, neutering them does not always stop this behavior, so it's best to neuter before it ever starts. Intact cats are territorial and unpleasant to live with.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Pet overpopulation is a crisis that leaves many unwanted cats in shelters every year.
                </p>
                <p>
                    Don't let your cat accidently worsen this problem.  Intact cats, or cats that have not been spayed or neutered, are very good at finding each other. The mating event only takes a moment. Accidental pregnancies are frequent in veterinary medicine, can endanger the health of your cat, and contribute to the overpopulation problem.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Talk to your veterinarian about when you should spay or neuter your kitten.
                </p>
                <p>
                    There is seldom an advantage to waiting to spay or neuter your cat, so don't delay in having this conversation with your veterinarian.
                </p>
                <div className="Center">
                    <img src={cat8} alt="Cat" />
                </div>
            </div>
        );
    }   
    
}