import React, { Component } from 'react';
import "../Content.css";
import dog6 from '../../images/dogs/dog006.png';
import dogCar from '../../images/dogs/dogCar.png';
import dog7 from '../../images/dogs/dog007.png';

export default class FleasAndTicks extends Component {
    render() {
        return (
            <div className="C-width">
                <h1>Fleas & Ticks</h1>
                <p>
                    "But was there ever a dog that praised his fleas?" &mdash; <a target="_blank" rel="noopener noreferrer" href='https://en.wikipedia.org/wiki/W._B._Yeats'><em>William Butler Yeats</em></a>
                </p>
                <h2 className="H-turn">Main Points</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Fleas and ticks spread disease and can make your dog miserable.
                </p>
                <p>
                    Fleas are a common cause of allergies in dogs. Fleas can cause significant blood loss that isn't always easy to spot by just looking at your dog.  They can also give your dog tapeworms, an intestinal parasite, and transmit Bartonella, a bacterium, which can contribute to other diseases.  Ticks carry numerous diseases that threaten the health of your dog; the most common include ehrlichiosis, Rocky Mountain Spotted Fever, and Lyme disease.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    It is easier to prevent a flea problem than to treat a flea infestation.
                </p>
                <p>
                    Many medications are available that will prevent fleas from harming your dog.  There are chewable tablets, topical medications, and even flea-preventing collars. Talk to your veterinarian about which preventative is best for you and your dog.
                    Once you have an infestation, it becomes necessary to decontaminate your home and yard as most of the flea burden is in the environment, not on your dog.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    In most cases, flea preventatives should be used year-round.
                </p>
                <p>
                    In warmer regions, fleas are a year-round threat. In cooler climates, fleas may not survive in freezing temperatures, but flea populations exist indoors.  It is in the mild temperatures of fall and spring that flea populations really thrive, not in the extreme temperatures of summer.  Most flea preventatives are more effective when used year-round, even if your area experiences a true winter.  Considering the serious risk of disease and the relative affordability of preventative medication, we recommend protecting your dog against fleas year-round.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={dog6} alt="Dog" />
                </div>
                <h2 className="H-turn Right">Preventatives</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Talk to your veterinarian about which flea and tick preventative is best for you and your dog.
                </p>
                <p>
                    We have provided a few of the many options that you can use to keep your dog flea-and-tick-free.  The topical medications listed below must be applied correctly, so follow the instructions on the package insert. Usually, these will instruct you to part the hair between the shoulder blades on the back of your dog and apply the product directly to the skin.  Some oral medications may only be effective if given with a meal.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Simparica
                </p>
                <p>
                    This oral medication is given monthly to prevent fleas, ticks, and mites.  It's a great choice for any dog over 6 months of age.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Trifexis
                </p>
                <p>
                    This oral medication is given monthly to prevent heartworm disease in dogs. It also protects against fleas. Trifexis should be given with a meal to maximize its effectiveness.  It does not protect against ticks, so talk to your veterinarian about tick prevention and what appropriate medication could be added to protect your dog.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Revolution
                </p>
                <p>
                    Revolution is a topical medication applied monthly that prevents heartworm disease, kills fleas, treats ear mites, and protects against American dog tick infestations.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Seresto Flea and Tick Collar
                </p>
                <p>
                    This collar protects against fleas and ticks for 8 months!  It's easy to put on and a good alternative for pet parents who don't want to give oral or topical flea preventatives monthly.  Bayer makes this product for <a target="_blank"  rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B00B8CG58U/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B00B8CG58U&linkCode=as2&tag=dogscatsmon0a-20&linkId=84786fe652858177282a87937f235f7f">small dogs</a>, under 18 pounds, and <a target="_blank"  rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B00B8CG602/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B00B8CG602&linkCode=as2&tag=dogscatsmon0a-20&linkId=110ecc6bfd2d7e6bc315a41cec90e2a3">large dogs</a>, over 18 pounds.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Frontline Plus
                </p>
                <p>
                    This topical medication is applied monthly and kills both fleas and ticks.  Merial makes this product for <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B0002J1FLW/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B0002J1FLW&linkId=50cc1f3a21605245e179a4fdf8d27eac">small dogs</a> (5-22 pounds), <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B0002J1FNK/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B0002J1FNK&linkId=fd60786a4a7d28d3820de2c12a2f70c4">medium dogs</a> (23-44 pounds), and <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B0002J1FOO/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B0002J1FOO&linkId=89cb5a28ef4e8651e19d7d9365f15b64">large dogs</a> (45-88 pounds).
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Bravecto
                </p>
                <p>
                    This oral or topical medication protects against fleas and most ticks for 3 months. The oral tablet is a great alternative for pet parents who do not like to apply topical medications to their dog.  It requires a prescription, so talk to your veterinarian if you want to explore this option for your dog.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Capstar
                </p>
                <p>
                    This oral medication is given to kill adult fleas and works almost immediately. It is often used to treat flea-infested animals, but does not provide long-term protection. Elanco makes this product for <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B005Z6UL1M/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B005Z6UL1M&linkId=034849112b59e702b1c682f03e00fbbc">small dogs</a> (2-25 pounds) and <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B01ELLHVK0/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B01ELLHVK0&linkId=57f161fa1878b766eb2f05ef6f0016ca">medium to large dogs</a> (over 25 pounds).
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={dogCar} alt="Dog" />
                </div>
                <h2 className="H-turn">Home & Yard</h2>
                <p  className="P-top" style={{fontWeight: 'bold'}}>
                    Once you have an active flea infestation, most of the flea burden exists in immature forms that live in your home and yard, not directly on your dog.
                </p>
                <p>
                    At this point, using a flea preventative on your dog is not enough to protect against the growing flea population.  Thorough vacuuming and environmental powders or sprays are necessary to remove the flea burden from the environment.  These should be used in combination with flea preventative medications on all your pets. The same fleas that target dogs also target cats, so any cats in your home must also be on adulticidal flea preventative.
                    After vacuuming thoroughly, apply a product like <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B000MS6Q2Q/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B000MS6Q2Q&linkId=0161313050b6192845dcc5954414e76f">Fleabusters</a> to kill the larval flea population in your home.
                    It is also important to use an <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B002OFIQ9Q/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B002OFIQ9Q&linkId=01b3c9784ba4b954d42d87f41e89cec9">outdoor product</a> to treat areas around your home that may be harboring a flea population.  Shady areas under trees and crawl spaces are especially welcoming flea habitats, so concentrate your treatment efforts in these areas.
                </p>
                <div className="Center">
                    <img src={dog7} alt="Dog" />
                </div>
            </div>
        );
    }

}
