import React, { Component } from 'react';
import "../Content.css";
import cat7 from '../../images/cats/cat007.png';
import cat5 from '../../images/cats/cat005.png';
import cat6 from '../../images/cats/cat006.png';

export default class CatHeartworms extends Component {
    render() {
        return (
            <div className="C-width">
                <h1>Heartworms</h1>
                <p>
                    "No “natural” or herbal therapies have been shown to be safe and effective prevention or treatment for heartworm disease." &mdash; <a target="_blank" rel="noopener noreferrer" href='https://www.heartwormsociety.org/images/pdf/Canine-Guidelines-Summary.pdf'><em>American Heartworm Society</em></a>
                </p>
                <h2 className="H-turn">Main Points</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Heartworms, although spread by mosquitoes, are a year-round threat to the health of your cat.
                </p>
                <p>
                    You should give your cat heartworm preventative medication year-round.  Even indoor-only cats are at risk, as mosquitoes can easily sneak into your home through a window or door.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Feline heartworm disease can be fatal, and there is no approved medical treatment.
                </p>
                <p>
                    Unlike dogs, there is no approved medical treatment to get rid of heartworms in cats.  Cat heartworm disease often presents as an emergency because the cat's immune system is very aggressive about these invaders. Surgery may be an option for some cats, but it is much more expensive than monthly prevention.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={cat7} alt="Cat" />
                </div>
                <h2 className="H-turn Right">Preventatives</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Oral or topical medication should be given monthly to prevent heartworm disease.
                </p>
                <p>
                    Several oral and topical medications exist, and a few examples have been provided below. These medications require a prescription, so talk with your veterinarian about which option is best for you and your cat.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Revolution Plus
                </p>
                <p>
                    Revolution Plus is a topical medication applied monthly that also protects against fleas, ticks, ear mites, hookworms, and roundworms.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Heartgard
                </p>
                <p>
                    Heartgard is an oral medication given monthly that also protects against intestinal hookworms.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Advantage Multi
                </p>
                <p>
                    Advantage Multi is a topical medication applied monthly that also protects against fleas, ear mites, hookworms, and roundworms.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={cat5} alt="Cat" />
                </div>
                <h2 className="H-turn">Treatment</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Heartworm disease cannot be treated medically in cats, and surgery may be necessary to remove the worms from the heart.
                </p>
                <p>
                    Signs of heartworm disease in cats include respiratory distress and may appear similar to feline asthma or heart disease.  If your veterinarian suspects heartworm disease, blood tests and an ultrasound may be necessary. Even if heartworms are confirmed, the treatment options are limited.  This disease is much easier to prevent with monthly medication, so don't leave your cat unprotected.
                </p>
                <div className="Center">
                    <img src={cat6} alt="Cat" />
                </div>
            </div>
        );
    }

}
