import React, { Component } from 'react';
import "../Content.css";

export default class MicrochipsSub extends Component {
    render() {
        return (
            <div className="C-width">
                <p className="References">
                    <sup>1</sup>Lord LK, Ingwersen W, Gray JL, Wintz DJ. Characterization of animals with microchips entering animal shelters. Journal of the American Veterinary Medical Association. 2009;235(2):160–7
                </p>
            </div>
        );
    }   
    
}