import React, { Component } from 'react';
import "../Content.css";
import cat9 from '../../images/cats/cat009.png';
import cat10 from '../../images/cats/cat010.png';
import cat11 from '../../images/cats/cat011.png';
import cat12 from '../../images/cats/cat012.png';
import cat13 from '../../images/cats/cat013.png';

export default class CatVaccines extends Component {
    render() {
        return (
            <div className="C-width">
                <h1>Vaccines</h1>
                <p>
                    "Without vaccination, many cats become seriously ill or may even die from diseases that their immune system is unable to fight effectively on its own. The use of vaccines has prevented death and disease
                    in millions of cats." &mdash; <a target="_blank" rel="noopener noreferrer" href='https://www.catvets.com/public/PDFs/ClientBrochures/ClientHandoutVaccination.pdf'><em>American Association of Feline Practitioners</em></a>
                </p>
                <h2 className="H-turn">Main Points</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Panleukopenia virus (FPV, feline parvovirus), feline herpesvirus-1 (FHV-1, rhinotracheitis), and feline calicivirus (FCV) vaccines are generally considered core for all cats.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Feline leukemia virus (FeLV) is generally considered core for all kittens, but may not be necessary for some indoor-only adult cats.
                </p>
                <p>
                    Panleukopenia, feline herpesvirus-1, and calicivirus are often combined into one vaccine. This combo vaccine may or may not contain a vaccine for rabies virus. FeLV vaccination is usually recommended for all kittens under one year of age since they are at the greatest risk of catching this contagious disease.  Your veterinarian is the best source of information on what vaccinations should be administered to your cat.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Vaccine reactions are rare, but pet parents should be aware of this risk.
                </p>
                <p>
                    With any vaccination, there is a very small risk of an unwanted vaccine reaction.  We hope to get some level of immune response from the administered vaccination, so it is likely that your cat will act a bit out of it (sore, tired, less playful) and may even have a low-grade fever after receiving vaccinations.  Mild GI upset, vomiting, and diarrhea are the most common vaccine reactions. If your cat develops facial swelling, collapses, or has any difficulty breathing, your cat should see a veterinarian immediately.  Again, these reactions are rare, and your veterinarian considers these risks when they make recommendations about which vaccines are core for your cat.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Feline injection site sarcomas are rare, but cat parents should be aware of this risk to help identify this adverse event early.
                </p>
                <p>
                    Many factors can contribute to the development of a feline injection site sarcoma, a dangerous tumor that can threaten the health of your cat.  Since this risk cannot be eliminated for any cat, cat parents should be aware of what body area their cat's vaccination was injected in.  A small lump may be present after vaccination, but if this lump hangs out for three or more months, ever gets larger than two centimeters, or continues to increase in size one month after the vaccination, then cat parents should notify their veterinarian immediately.<sup>1</sup>  A lump with any of these characteristics may need to be removed, a task much more easily performed early in the disease process.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={cat9} alt="Cat" />
                </div>

                <h2 className="H-turn Right">Combo Vaccine</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    A combination vaccine often referred to as FRCP should be administered several times, a few weeks apart for kittens, once at their first annual exam, and then at least every three years.
                </p>
                <p>
                    This combination vaccine protects against rhinotracheitis (FHV-1), calicivirus, and feline parvovirus (panleukopenia).
                    During kittenhood, the timing of vaccination is important.
                    This is due to maternal antibodies which are absorbed from the mother's milk soon after the kitten is born.
                    These maternal antibodies are a good thing, as they help protect your kitten against these common infectious threats.
                    It is important that the final kitten vaccines are given around 16 weeks of age to ensure that your kitten can mount
                    a strong immune response.  Several combination vaccines can be boostered once every three years, so talk to your
                    veterinarian about which combination vaccine is most appropriate for your cat.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={cat11} alt="Cat" />
                </div>
                <h2 className="H-turn">Feline Leukemia</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Feline leukemia virus (FeLV) vaccination is generally considered core for all kittens under one year of age.
                </p>
                <p>
                    FeLV is often spread by grooming, and, since kittens do not have a fully competent immune system, they are at the greatest risk of catching this disease.  This disease increases the risk of other infections and cancer, so certain at-risk adult cats will also benefit from vaccination.  The benefits will depend on your cat's lifestyle and risk of exposure, and your veterinarian will assess if FeLV vaccination is appropriate for your cat.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={cat12} alt="Cat" />
                </div>
                <h2 className="H-turn Right">Rabies</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Rabies vaccination is not required in the state of Hawaii but is necessary for cats that travel.
                </p>
                <p>
                    If your cat plans on traveling outside of Hawaii, please let your veterinarian know well ahead of time.  Most states require your cat be vaccinated against rabies before entry.
                    Blood testing (FAVN testing) is required for cats entering Hawaii to show that their immune system has responded to the rabies vaccine.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={cat10} alt="Cat" />
                </div>
                <h2 className="H-turn">Others</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Other vaccines include those against feline immunodeficiency virus (FIV), <em>Bordetella</em>, <em>Chlamydophila</em>, and feline infectious peritonitis (FIP).
                </p>
                <p>
                    FIV, Bordetella, and Chlamydophila vaccines are generally not recommended, but may benefit certain cats or groups of cats that are at increased risk.  FIP vaccination is generally not recommended.  Your veterinarian is the best source of information about which additional vaccines may benefit your cat.
                </p>
                <div className="Center">
                    <img src={cat13} alt="Cat" />
                </div>
            </div>
        );
    }

}
