import React from "react";
import { Route, Switch } from "react-router-dom";
import Header from "./components/Header";
import ContentHome from "./components/content/ContentHome";
import Footer from "./components/Footer";
// 404 includes footer with "Home" only
import NotFound from "./components/NotFound";
// Dogs
import HeaderDogs from "./components/HeaderDogs";
import ContentDogs from "./components/content/ContentDogs";
import Nutrition from "./components/content/Dogs/Nutrition";
import NutritionSub from "./components/content/Dogs/NutritionSub";
import Fleas from "./components/content/Dogs/FleasAndTicks";
import Heartworms from "./components/content/Dogs/Heartworms";
import Spay from "./components/content/Dogs/SpayAndNeuter";
import Vaccines from "./components/content/Dogs/CoreVaccines";
import Dental from "./components/content/Dogs/DentalCare";
import Behavior from "./components/content/Dogs/Behavior";
import Microchips from "./components/content/Dogs/Microchips";
import MicrochipsSub from "./components/content/Dogs/MicrochipsSub";
import Grooming from "./components/content/Dogs/Grooming";
import Toxins from "./components/content/Dogs/Toxins";
import Grief from "./components/content/Dogs/LossAndGrief";
// Cats
import HeaderCats from "./components/HeaderCats";
import ContentCats from "./components/content/ContentCats";
import CatNutrition from "./components/content/Cats/Nutrition";
import CatFleas from "./components/content/Cats/FleasAndTicks";
import CatHeartworms from "./components/content/Cats/Heartworms";
import CatSpay from "./components/content/Cats/SpayAndNeuter";
import CatVaccines from "./components/content/Cats/CoreVaccines";
import CatVaccinesSub from "./components/content/Cats/CoreVaccinesSub";
import CatDental from "./components/content/Cats/DentalCare";
import CatBehavior from "./components/content/Cats/Behavior";
import CatMicrochips from "./components/content/Cats/Microchips";
import CatGrooming from "./components/content/Cats/Grooming";
import CatToxins from "./components/content/Cats/Toxins";
import CatGrief from "./components/content/Cats/LossAndGrief";


export default () =>
    <Switch>
      <Route path="/" exact render={() => <div><Header/><ContentHome/></div>} />
      <Route path="/dogs" exact render={() => <div><HeaderDogs/><ContentDogs/><Footer/></div>} />
      <Route path="/cats" exact render={() => <div><HeaderCats/><ContentCats/><Footer/></div>} />
      <Route path="/dogs/nutrition" exact render={() => <div><HeaderDogs/><Nutrition/><Footer/><NutritionSub/></div>} />
      <Route path="/dogs/fleas_and_ticks" exact render={() => <div><HeaderDogs/><Fleas/><Footer/></div>} />
      <Route path="/dogs/heartworms" exact render={() => <div><HeaderDogs/><Heartworms/><Footer/></div>} />
      <Route path="/dogs/spay_and_neuter" exact render={() => <div><HeaderDogs/><Spay/><Footer/></div>} />
      <Route path="/dogs/core_vaccines" exact render={() => <div><HeaderDogs/><Vaccines/><Footer/></div>} />
      <Route path="/dogs/dental_care" exact render={() => <div><HeaderDogs/><Dental/><Footer/></div>} />
      <Route path="/dogs/behavior" exact render={() => <div><HeaderDogs/><Behavior/><Footer/></div>} />
      <Route path="/dogs/microchips" exact render={() => <div><HeaderDogs/><Microchips/><Footer/><MicrochipsSub/></div>} />
      <Route path="/dogs/grooming" exact render={() => <div><HeaderDogs/><Grooming/><Footer/></div>} />
      <Route path="/dogs/toxins" exact render={() => <div><HeaderDogs/><Toxins/><Footer/></div>} />
      <Route path="/dogs/loss_and_grief" exact render={() => <div><HeaderDogs/><Grief/><Footer/></div>} />
      <Route path="/cats/nutrition" exact render={() => <div><HeaderCats/><CatNutrition/><Footer/><NutritionSub/></div>} />
      <Route path="/cats/fleas_and_ticks" exact render={() => <div><HeaderCats/><CatFleas/><Footer/></div>} />
      <Route path="/cats/heartworms" exact render={() => <div><HeaderCats/><CatHeartworms/><Footer/></div>} />
      <Route path="/cats/spay_and_neuter" exact render={() => <div><HeaderCats/><CatSpay/><Footer/></div>} />
      <Route path="/cats/core_vaccines" exact render={() => <div><HeaderCats/><CatVaccines/><Footer/><CatVaccinesSub/></div>} />
      <Route path="/cats/dental_care" exact render={() => <div><HeaderCats/><CatDental/><Footer/></div>} />
      <Route path="/cats/behavior" exact render={() => <div><HeaderCats/><CatBehavior/><Footer/></div>} />
      <Route path="/cats/microchips" exact render={() => <div><HeaderCats/><CatMicrochips/><Footer/><MicrochipsSub/></div>} />
      <Route path="/cats/grooming" exact render={() => <div><HeaderCats/><CatGrooming/><Footer/></div>} />
      <Route path="/cats/toxins" exact render={() => <div><HeaderCats/><CatToxins/><Footer/></div>} />
      <Route path="/cats/loss_and_grief" exact render={() => <div><HeaderCats/><CatGrief/><Footer/></div>} />
      <Route render={() => <div><Header/><NotFound/></div>} />
    </Switch>;
