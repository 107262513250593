import React, { Component } from 'react';
import "../Content.css";
import dogDental from '../../images/dogs/dogDental.png';

export default class Microchips extends Component {
    render() {
        return (
            <div className="C-width">
                <h1>Microchips</h1>
                <p>
                    "Microchips are great for permanent identification that is tamper-proof, but nothing replaces a collar with up-to-date identification tags when it comes
                    to quickly identifying a found pet." &mdash; <a target="_blank" rel="noopener noreferrer" href='https://ebusiness.avma.org/files/productdownloads/LR_COM_ClientBroch_Microchipping_020516.pdf'><em>American Veterinary Medical Association</em></a>
                </p>
                <h2 className="H-turn">Main Points</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Microchipping your dog improves the chances that your four-legged family member will be returned home safely.
                </p>
                <p>
                    There is good evidence to show that lost dogs with microchips are returned home more frequently than dogs without microchips.<sup>1</sup> Give your dog every opportunity to make it home safely.  Microchips are relatively cheap, usually under $50, and are one of the first things an animal shelter or veterinarian will check when they receive a lost dog.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Remember to register your dog's microchip with its manufacturer.
                </p>
                <p>
                    Once your veterinarian has microchipped your dog, you will need to register your information with the manufacturer.  This will ensure that your contact information is associated with your dog's microchip number.  If your dog is recovered by a shelter or veterinary clinic, they can look up the information associated with your dog's microchip number using a tool like the <a target="_blank" rel="noopener noreferrer" href="http://www.petmicrochiplookup.org/">AAHA Pet Microchip Lookup</a>.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Microchips are not GPS locators.
                </p>
                <p>
                    Microchips require a scanner to read and do not send out a signal that you can track with your smartphone.  An innovative company recognized this opportunity and created <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B01N7MWKWY/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B01N7MWKWY&linkId=8a68aa648fce2eeca2d647a618c2d25b">Whistle</a> which is a GPS locator that can be attached to your dog's collar.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Microchips are required for dogs that travel to and from Hawaii.
                </p>
                <p>
                    If your dog travels, his or her microchip number will be used on health certificates.  A microchip is also needed for the FAVN (rabies vaccine response) test form for dogs entering Hawaii.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Facial recognition software is an emerging tool to help lost dogs get home safe.
                </p>
                <p>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.findingrover.com/lost">Finding Rover</a> is a free app that uses facial recognition to reunite lost pets with their parents.  Its a great idea to register your pet and will become increasing useful as this app gains popularity.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    A collar tag with your phone number is still one of the best ways to ensure speedy return of your dog.
                </p>
                <p>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/s?k=collar+tag&ref=nb_sb_noss_2">Collar tags</a> are still the most useful and widely used pet identification method.  A collar tag should be used in combination with a microchip, since collars can slip off and tags can be lost.
                </p>
                <div className="Center">
                    <img src={dogDental} alt="Dog" />
                </div>
            </div>
        );
    }

}
