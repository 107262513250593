import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

export default class Footer extends Component {
    render() {
        return (
          <div>
            <div className="Footer">
              <Link className="Button-grey" to="/dogs">Dogs</Link>
              <Link className="Button-grey" to="/cats">Cats</Link>
              <Link className="Button-grey" to="/">Home</Link>
            </div>
          </div>
        );
    }

}
