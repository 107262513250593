import React, { Component } from 'react';
import "../Content.css";
import cat2 from '../../images/cats/cat002.png';
import cat3 from '../../images/cats/cat003.png';
import cat4 from '../../images/cats/cat004.png';

export default class CatFleas extends Component {
    render() {
        return (
            <div className="C-width">
                <h1>Fleas & Ticks</h1>
                <p>
                    "After ensuring the removal of pests, you can safely go ahead with collecting your cat's hair and make clean cat hair felt" &mdash; Kaori Tsutaya, <a target="_blank" rel="noopener noreferrer" href='https://www.amazon.com/gp/product/1594745250/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=1594745250&linkId=871f15906f04ab82bb195ffdfb77e587'><em>Crafting with Cat Hair: Cute Handicrafts to make with Your Cat</em></a>
                </p>
                <h2 className="H-turn">Main Points</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Fleas and ticks spread disease and can make your cat miserable.
                </p>
                <p>
                    Fleas are a common cause of allergies in cats. Fleas can cause significant blood loss that isn't always easy to spot by just looking at your cat.  They can also give your cat tapeworms, an intestinal parasite, and transmit other infectious diseases such as bartonellosis and mycoplasmosis.  Ticks carry numerous diseases and are a risk to cats that spend any amount of time outdoors.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    It is easier to prevent a flea problem than to treat a flea infestation.
                </p>
                <p>
                    Many medications are available that will prevent fleas from harming your cat.  There are chewable tablets, topical medications, and even flea-preventing collars.
                    Talk to your veterinarian about which preventative is best for you and your cat. Once you have an infestation, it becomes necessary to decontaminate your home and yard as most of the flea burden is in the environment, not on your cat.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    In most cases, flea preventatives should be used year-round.
                </p>
                <p>
                    In warmer regions, fleas are a year-round threat. In cooler climates, fleas may not survive in freezing temperatures, but flea populations exist indoors.
                    It is in the mild temperatures of fall and spring that flea populations really thrive, not in the extreme temperatures of summer.
                    Most flea preventatives are more effective when used year-round, even if your area experiences a true winter.  Considering the serious risk of
                    disease and the relative affordability of preventative medication, we recommend protecting your cat against fleas year-round.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Do not use dog flea preventatives on your cat.
                </p>
                <p>
                    Several dog flea preventatives are toxic to cats. Some are extremely toxic. Use a flea preventative that is designed to be safe for your cat.  This should be clearly stated on the product label.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={cat2} alt="Cat" />
                </div>
                <h2 className="H-turn Right">Preventatives</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Talk to your veterinarian about which flea and tick preventative is best for you and your cat.
                </p>
                <p>
                    We have provided a few of the many options that you can use to keep your cat flea-and-tick-free.
                    The topical medications listed below must be applied correctly, so follow the instructions on the package insert.
                    Usually, these will instruct you to part the hair between the shoulder blades on the back of your cat and apply the product
                    directly to the skin.  Some oral medications may only be effective if given with a meal.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Revolution Plus
                </p>
                <p>
                    This topical medication is given monthly and protects against fleas, ear mites, and heartworms. It can also protect against roundworm and hookworm infections. The added heartworm protection makes this a great option for cats. It requires a prescription, so talk to your veterinarian if you want to explore this option for your cat.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Cheristin
                </p>
                <p>
                    This topical medication is applied monthly to protect against fleas.  It starts killing adult fleas within 30 minutes.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Seresto Flea and Tick Collar
                </p>
                <p>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B00B8CG5NK/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B00B8CG5NK&linkId=b852602adbfe11b8ccedb7de3a7b0259">This collar</a> protects against fleas and ticks for 8 months!  It's easy to put on and a good alternative for pet parents who don't want to give oral or topical flea preventatives monthly.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Frontline Plus
                </p>
                <p>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B0002J1F7G/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B0002J1F7G&linkId=b028655d46864d76fe64422b4b1a082a">This topical medication</a> is applied monthly and protects your cat from both fleas and ticks.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Capstar
                </p>
                <p>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B005Z6UL1M/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B005Z6UL1M&linkId=034849112b59e702b1c682f03e00fbbc">This oral medication</a> is given to kill adult fleas and works almost immediately. It is often used to treat flea-infested animals, but does not provide long-term protection.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={cat3} alt="Cat" />
                </div>
                <h2 className="H-turn">Home & Yard</h2>
                <p  className="P-top" style={{fontWeight: 'bold'}}>
                    Once you have an active flea infestation, most of the flea burden exists in immature forms that live in your home and yard, not directly on your pet.
                </p>
                <p>
                    At this point, using a flea preventative on your cat is not enough to protect against the growing flea population.  Thorough vacuuming and environmental powders or sprays are necessary to remove the flea burden from the environment.  These should be used in combination with flea preventative medications on all your pets. The same fleas that attack cats also attack dogs, so any dogs in your home must also be on adulticidal flea preventative.
                    After vacuuming thoroughly, apply a product like <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B000MS6Q2Q/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B000MS6Q2Q&linkId=0161313050b6192845dcc5954414e76f">Fleabusters</a> to kill the larval flea population in your home.
                    It is also very important to use an <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B002OFIQ9Q/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B002OFIQ9Q&linkId=01b3c9784ba4b954d42d87f41e89cec9">outdoor product</a> to treat areas around your home that may be harboring a flea population.  Shady areas under trees and crawl spaces are especially welcoming flea habitats, so concentrate your treatment efforts in these areas.
                </p>
                <div className="Center">
                    <img src={cat4} alt="Cat" />
                </div>
            </div>
        );
    }

}
