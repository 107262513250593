import React from 'react';
import { Link } from "react-router-dom";
import "./content/Content.css";

export default () => 
            <div>
                <div className="Center" style={{padding: '1rem 0 1.5rem 0', maxWidth: 900}}>
                        <h1>Meow we're lost... 404</h1>
                </div>
                <Link className="Button-blue" to="/">Home</Link>
            </div>;