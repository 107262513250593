import React, { Component } from 'react';
import "../Content.css";
import dogSnow from '../../images/dogs/dogSnow.png';
import dogsHammock from '../../images/dogs/dogsHammock.png';
import puppyBed from '../../images/dogs/puppyBed.png';

export default class Heartworms extends Component {
    render() {
        return (
            <div className="C-width">
                <h1>Heartworms</h1>
                <p>
                    "No “natural” or herbal therapies have been shown to be safe and effective prevention or treatment for heartworm disease." &mdash; <a target="_blank" rel="noopener noreferrer" href='https://www.heartwormsociety.org/images/pdf/Canine-Guidelines-Summary.pdf'><em>American Heartworm Society</em></a>
                </p>
                <h2 className="H-turn">Main Points</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Heartworms, although spread by mosquitoes, are a year-round threat to the health of your dog.
                </p>
                <p>
                    You should give your dog heartworm preventative medication year-round, and your dog should be tested for heartworm disease every year by your veterinarian.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    It is always cheaper to prevent heartworm disease than it is to medically or surgically remove the adult worms.
                </p>
                <p>
                    Adult heartworms live in your dog's heart. Early in the disease, there may be no obvious signs of infection. With time, the disease can become quite severe, and it will require medical treatment and sometimes surgery to remove the worms.  Treatment is expensive and can be life-threatening to your dog.  It is always cheaper to prevent this disease than to treat it.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={dogSnow} alt="Dog" />
                </div>
                <h2 className="H-turn Right">Preventatives</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Oral or topical medication should be given monthly to prevent heartworm disease.
                </p>
                <p>
                    Several oral and topical medications exist, and a few examples have been provided below. These medications require a prescription, and yearly heartworm testing is important to ensure their effectiveness. Talk with your veterinarian about which option is best for you and your dog.  Alternatively, your veterinarian may be able to administer an injectable form of a similar drug that provides 6 months of protection.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    ProHeart 6
                </p>
                <p>
                    ProHeart 6 is an injectable heartworm preventative given every 6 months by your veterinarian.  It is not safe for dogs currently infected with heartworms, so it is necessary to do bloodwork and test for heartworms prior to administration.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Interceptor Plus
                </p>
                <p>
                    Interceptor Plus is an oral medication given monthly that also protects against intestinal hookworms, roundworms, whipworms, and tapeworms.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Revolution
                </p>
                <p>
                    Revolution is a topical medication applied monthly that prevents heartworm disease, kills fleas, treats ear mites, and protects against American dog tick infestations.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Trifexis
                </p>
                <p>
                    Trifexis is an oral medication given monthly that also protects against hookworms, roundworms, whipworms, and even fleas.  It may not be a safe choice if your dog is currently infected with heartworms.  Also, it must be given with a meal to increase its absorption and ensure its effectiveness.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={dogsHammock} alt="Dog" />
                </div>
                <h2 className="H-turn">Treatment</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Once infected with adult worms, heartworm treatment is expensive and can be dangerous.
                </p>
                <p>
                    It is possible that you learned about heartworm prevention too late or adopted a dog with heartworm disease.  Now you must treat the adult worms in your dog's heart.  There is only one approved drug to do this and it is relatively expensive. The treatment can take several months and involves pretreatment with an antibiotic, a steroid, and a heartworm preventative to target the younger life stages of the heartworms. Then, multiple injections are given over the course of one month.  In severe cases with larger worm burdens, surgery may be required to remove the adult worms from your dog's heart.  In all cases, it is important to minimize exercise and excitement during and following heartworm treatment which often means confining your dog in a kennel.
                </p>
                <div className="Center">
                    <img src={puppyBed} alt="Dog" />
                </div>
            </div>
        );
    }

}
