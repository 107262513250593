import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Content.css";
import cat1 from '../images/cats/catFood.png';
import cat2 from '../images/cats/catFlea.png';
import cat3 from '../images/cats/catUnder.png';
import cat4 from '../images/cats/kitten1.png';
import cat5 from '../images/cats/catExotic.png';
import cat6 from '../images/cats/catTeeth.png';
import cat7 from '../images/cats/catStanding.png';
import cat8 from '../images/cats/kittenMicrochip.png';
import cat9 from '../images/cats/catGrooming.png';
import cat11 from '../images/cats/catPlants.png';
import cat12 from '../images/cats/catBridge.png';

export default class ContentCats extends Component {
    render() {
        return (
            <div className="C-width">
                <h1>CATS</h1>
                <p style={{padding: '0 0 1.5rem 0'}}>
                  <em>"A cat is a patient listener, even when you're telling a story for the third time.
                  A cat is the most dependable alarm clock you'll ever have. A cat will show you how to enjoy life."
                  </em> &mdash; Pam Johnson-Bennett, <em><a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/0143119796/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=0143119796&linkId=c1bd8043a677693a6e3cbef4fc0917ec">Think Like a Cat</a></em>
                </p>
                <h2 className="H-turn">Nutrition</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                  Good nutrition is the key to making sure your cat lives a happy, healthy life.
                </p>
                <p>
                  A great deal of marketing goes into pet food, but this marketing provides no
                  nutritional benefit to your cat. We want to help you understand the difference between
                  quality marketing and quality cat food. Start by looking for an AAFCO statement near
                  the ingredients listed on the food. An AAFCO statement suggests that nutritional
                  standards for a complete and balanced diet were followed by the manufacturer.
                </p>
                <div className="Center" style={{paddingTop: 0}}>
                  <Link className="Button-blue" to="/cats/nutrition">Learn more</Link>
                </div>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                  <img src={cat1} alt="Cat" />
                </div>
                <h2 className="H-turn Right">Fleas & Ticks</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                  Fleas and ticks are parasites, and they spread disease. Don't let your cat invite them to the party.
                </p>
                <p>
                  If your cat isn't protected from fleas and ticks then you are in flea-nile. Every cat should be protected&ndash;even indoor-only cats.
                  There are many preventative medications to choose from, and we want to help guide you to an informed decision about what preventatives are best for you and your cat.
                </p>
                <div className="Center" style={{paddingTop: 0}}>
                  <Link className="Button-blue" to="/cats/fleas_and_ticks">Learn more</Link>
                </div>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                  <img src={cat2} alt="Cat" />
                </div>
                <h2 className="H-turn">Heartworms</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Heartworm prevention is an important part of caring for your cat and common preventative medications help protect against other parasites as well.
                </p>
                <p>
                    Heartworms are more of a dog thing, but they can cause severe
                    disease in cats. Although this infection is spread by mosquitoes, even indoor cats are at risk.
                    The consequences of this disease can be serious enough that we recommend preventative heartworm medication for all cats.
                </p>
                <div className="Center">
                  <Link className="Button-blue" to="/cats/heartworms">Learn more</Link>
                </div>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                  <img src={cat3} alt="Cat" />
                </div>
                <h2 className="H-turn Right">Spay & Neuter</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                  Spaying and neutering prevents many types of cancer, health problems, behavioral problems, and helps ensure your cat isn’t contributing to pet overpopulation.
                </p>
                <p>
                  There are a lot of good reasons to spay or neuter your cat and only a few
                  good reasons not to. Talk to your veterinarian about spaying and neutering and at
                  what age it is best for your kitten.
                </p>
                <div className="Center" style={{paddingTop: 0}}>
                  <Link className="Button-blue" to="/cats/spay_and_neuter">Learn more</Link>
                </div>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                  <img src={cat4} alt="Cat" />
                </div>
                <h2 className="H-turn">Vaccines</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                  Core vaccines are the vaccines that should be administered to your cat.
                </p>
                <p>
                  Feline herpesvirus 1 (FHV1), feline calicivirus (FCV), feline panleukopenia virus (FPV), and
                  feline leukemia virus (FeLV) vaccines are generally considered core vaccines
                  for all cats. Your cat may benefit from additional vaccines, and we’ll provide some information about
                  those as well.  Feline injection site sarcomas are associated with vaccination and this risk should be discussed with your veterinarian.
                </p>
                <div className="Center" style={{paddingTop: 0}}>
                  <Link className="Button-blue" to="/cats/core_vaccines">Learn more</Link>
                </div>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                  <img src={cat5} alt="Cat" />
                </div>
                <h2 className="H-turn Right">Dental Care</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                  Dental care is an important, often overlooked, part of feline health.
                </p>
                <p>
                  Bad breath is just the tip of the iceberg; dental health can impact the overall health of your cat and even change your cat's behavior.
                  Talk to your veterinarian about your cat's dental health, and make sure your cat is getting regular dental cleanings.
                </p>
                <div className="Center" style={{paddingTop: 0}}>
                  <Link className="Button-blue" to="/cats/dental_care">Learn more</Link>
                </div>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                  <img src={cat6} alt="Cat" />
                </div>
                <h2 className="H-turn">Behavior</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                  Understanding cat behavior and proven training techniques will strengthen the relationship you have with your cat.
                </p>
                <p>
                  From litter box best practices to correcting problem behaviors, proper training can help you
                  build an amazing relationship with your cat.
                  Common behavior problems in cats can be avoided and corrected, and we want to point you in the right direction.
                </p>
                <div className="Center" style={{paddingTop: 0}}>
                  <Link className="Button-blue" to="/cats/behavior">Learn more</Link>
                </div>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                  <img src={cat7} alt="Cat" />
                </div>
                <h2 className="H-turn Right">Microchips</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                  Microchips are a safe, permanent way to identify your cat and get your cat back to you if your cat is ever lost.
                </p>
                <p>
                  Your cat wants to be microchipped. Even indoor-only cats should get a microchip.
                  Microchips are not GPS locators, but they are proven to help return lost pets to their families.
                  Don't forget to register your cat's microchip!
                </p>
                <div className="Center" style={{paddingTop: 0}}>
                  <Link className="Button-blue" to="/cats/microchips">Learn more</Link>
                </div>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                  <img src={cat8} alt="Cat" />
                </div>
                <h2 className="H-turn">Grooming</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                  Proper grooming keeps your cat looking sharp; it can also improve their health.
                </p>
                <p>
                  Brushing, bathing, nail trimming, and ear cleaning are just some of the cat grooming habits your cat wants you to know about.
                </p>
                <div className="Center" style={{paddingTop: 0}}>
                  <Link className="Button-blue" to="/cats/grooming">Learn more</Link>
                </div>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                  <img src={cat9} alt="Cat" />
                </div>
                <h2 className="H-turn Right">Toxins</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                  Toxic plants, food, and other materials can be easily avoided if you know what to watch out for.
                </p>
                <p>
                  Many cat parents know that lilies kill cats, but many other plants are dangerous as well. Common foods like caffeine, onions,
                  and alcohol can kill cats.  Dental floss and other strings can be very dangerous for cats.
                  Know the list of common things that you should keep away from your cat.
                </p>
                <div className="Center" style={{paddingTop: 0}}>
                  <Link className="Button-blue" to="/cats/toxins">Learn more</Link>
                </div>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                  <img src={cat11} alt="Cat" />
                </div>
                <h2 className="H-turn">Loss & Grief</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                  Losing your cat can be heartbreaking, and it happens to every cat parent.
                </p>
                <p>
                  We want to empower you with information about making hard decisions around
                  euthanasia and give you some tools for healthy grieving. Seek professional help if you need it.
                </p>
                <div className="Center" style={{paddingTop: 0}}>
                  <Link className="Button-blue" to="/cats/loss_and_grief">Learn more</Link>
                </div>
                <div className="Center" style={{padding: '0 0 2.5rem 0'}}>
                  <img src={cat12} alt="Cat" />
                </div>
            </div>
        );
    }

}
