import React, { Component } from 'react';
import "../Content.css";
import cat20 from '../../images/cats/cat020.png';
import cat21 from '../../images/cats/cat021.png';
import cat22 from '../../images/cats/cat022.png';
import kitten3 from '../../images/cats/kitten3.png';

export default class CatGrooming extends Component {
    render() {
        return (
            <div className="C-width">
                <h1>Grooming</h1>
                <p>
                    "The cat's saliva contains a natural odor neutralizer and that's why the kitty's fur smells so fresh after a tongue bath" &mdash; Pam Johnson-Bennett, <em><a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/0143119796/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=0143119796&linkId=c1bd8043a677693a6e3cbef4fc0917ec">Think Like a Cat</a></em>
                </p>
                <h2 className="H-turn">Main Points</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Be realistic.
                </p>
                <p>
                    If your cat isn't into it, it will not end well. It can help to introduce your kitten to the grooming tools and process early in life and make it a positive experience.  If you have an adult cat that is not a fan of brushing, bathing, or nail clipping, then make sure you take baby steps and use lots of positive reinforcement to get to your grooming goals.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Regular grooming is an excellent way to bond with your cat and can give you early warnings about changes in your cat's health.
                </p>
                <p>
                    Not only will positive grooming experiences strengthen the bond you have with your cat, they are also an opportunity to identify any skin issues, lumps or bumps, or other changes early.  Many diseases can cause changes to your cat's skin or coat.  Regular grooming can cue you in to changes that you can bring to the attention of your veterinarian.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Different cats have different grooming needs which can change as they age.
                </p>
                <p>
                    Some cats have no hair, while others seem to have extra. Cats are generally quite good at keeping themselves well groomed, but some senior cats are more relaxed about their grooming schedule and could use more assistance.  We have tried to provide some helpful generalizations about grooming below, but they do not necessarily apply to all cats.  Your veterinarian is an excellent resource if you have specific questions about grooming your cat.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={cat20} alt="Cat" />
                </div>
                <h2 className="H-turn Right">Brushing</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Regular brushing can help remove dead hairs, prevent tangles, and reduce shedding and hairballs.
                </p>
                <p>
                    Cats have relatively thin delicate skin, so brush gently and do not overdo it. Most cats will be quick to let you know if it doesn't feel good.  Many cats enjoy being brushed with a gentle <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B0002AR18M/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B0002AR18M&linkId=2ab9902b2f1351349ea1b7ebe1207615">rubber brush</a>. For some longer coats, a <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B00ZGPI3OY/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B00ZGPI3OY&linkId=28c31e29ebe6eaaba1712939f7de0ee9">slicker brush</a> can
                    be used. A <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B01IFQX1O2/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B01IFQX1O2&linkId=a65cee0315d8de2fa83cae6ddd244856">comb</a> may also be appropriate to remove small tangles. Regular brushing will prevent mats, or knots of hair, from forming.  Start by brushing weekly, and decrease or increase the frequency as you learn what is necessary to keep your cat's coat tangle free.  Some mats will be too knotted to brush
                    out. <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B01F2OZRAG/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B01F2OZRAG&linkId=bf8468006cc9dcd044a6c29893807e4f">Dematting combs</a> can be used, but must be used properly or their sharp blades will easily cut your cat's skin. We recommend a <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B0719DVKG9/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B0719DVKG9&linkId=50674b9cf2d13fac6a2a9e8b951c4343">small, quiet clipper</a> for removing mats, especially in tough areas with more delicate skin, like under the arms, in the groin, and around the neck.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={cat21} alt="Cat" />
                </div>
                <h2 className="H-turn">Bathing</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Bathing your cat may be necessary if your cat gets into something gross or smelly.
                </p>
                <p>
                    Cats do not require regular bathing, and most cats that have not been accustomed to positive bathing experiences from an early age will not enjoy bath time.  If you do need to bathe your cat, use a gentle veterinary <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B002WDB7UU/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B002WDB7UU&linkId=4f864d263424935afa4961c2c9e9bfd7">shampoo designed for cats</a>.  Cat hair root design differs slightly from humans, so human products are often too harsh for your cat. Before you begin, make sure you have all the supplies nearby.
                    Be gentle as you wash, avoid getting water in your cat's eyes or ears, and rinse thoroughly.  You can gently towel dry your cat working with the direction of the hair, or use a hairdryer (on cool) if your cat will tolerate it.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={cat22} alt="Cat" />
                </div>
                <h2 className="H-turn Right">Nail Clipping</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Regular nail clipping may help dull those razor-sharp claws.
                </p>
                <p>
                    If your cat shows affection by kneading at your leg with sharp claws, then you may want to trim the sharp points.  Use a <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B01GBSSKVU/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B01GBSSKVU&linkId=9a56b86a0a4b53c6b31904859913e48f">cat nail clipper</a> to trim your cat's nails.  Cats have living tissue, called the quick, in the center of each nail that contains a blood supply and nerve.  You'll want to trim your cat's nails to a point just before you would hit the quick.  If your cat's nail starts to bleed, then you have gone too far and cut the quick.  If this happens, it is a good idea to have 
                    some <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B0002H3RBU/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B0002H3RBU&linkId=71ddd50254bda8274d09f6ac7aa2b188">styptic powder</a> ready to stop the bleeding.  Simply press a small amount of this powder onto the bleeding quick.  In a pinch, some flour or cornstarch may work to stop a bleeding quick.  Some cats will not tolerate a nail trimming, and you may have to start by just gently handling the feet.  Others may only allow you to trim one or two nails.  Keep the nail clipping experience positive and go at your cat's pace or you will make it a much more difficult task in the future.
                </p>
                <div className="Center">
                    <img src={kitten3} alt="Cat" />
                </div>
            </div>
        );
    }   
    
}