import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Content.css";
import dog1 from '../images/dogs/dogFood.png';
import dog2 from '../images/dogs/dogFlea.png';
import dog3 from '../images/dogs/dogGeneric.png';
import dog4 from '../images/dogs/twoDogs.png';
import dog5 from '../images/dogs/dogBeach.png';
import dog6 from '../images/dogs/goldenTongue.png';
import dog7 from '../images/dogs/dogBehavior.png';
import dog8 from '../images/dogs/dogsTwo.png';
import dog9 from '../images/dogs/dogGrooming.png';
import dog11 from '../images/dogs/dogChocolate.png';
import dog12 from '../images/dogs/dogBridge.png';

export default class ContentDogs extends Component {
    render() {
        return (
            <div className="C-width">
                <h1>DOGS</h1>
                <p>
                    <em>"Let him roll in whatever-that-thing-is once in a while. Endure some traipsing through mud puddles. Walk off leash when you can."</em> &mdash; Alexandra Horowitz,
                    <em> <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/1416583432/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=1416583432&linkId=9a5f88f8a5636d5436eeaef41fbb6f42">
                    Inside of a Dog</a></em>
                </p>
                <h2 className="H-turn">Nutrition</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                  Good nutrition is the key to making sure your dog lives a happy, healthy life.
                </p>
                <p>
                  A great deal of marketing goes into pet food, but this marketing provides no nutritional benefit to your dog.
                  We want to help you understand the difference between quality marketing and quality dog food. Start by looking for an AAFCO Nutritional Adequacy Statement
                  near the ingredients listed on the food bag. An AAFCO Nutritional Adequacy Statement will tell you if the food is complete and balanced for the life stage of your dog.
                </p>
                <div className="Center" style={{paddingTop: 0}}>
                  <Link className="Button-blue" to="/dogs/nutrition">Learn more</Link>
                </div>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                  <img src={dog1} alt="Dog" />
                </div>
                <h2 className="H-turn Right">Fleas & Ticks</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                  Fleas and ticks are parasites, and they spread disease. Don't let your dog invite them to the party.
                </p>
                <p>
                  If your dog isn't protected from fleas and ticks then you are in flea-nile.
                  Every dog should be protected year-round. There are many preventative medications to choose from,
                  and we want to help guide you to an informed decision about what preventatives are best for you and your dog.
                </p>
                <div className="Center" style={{paddingTop: 0}}>
                  <Link className="Button-blue" to="/dogs/fleas_and_ticks">Learn more</Link>
                </div>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                  <img src={dog2} alt="Dog" />
                </div>
                <h2 className="H-turn">Heartworms</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Heartworm prevention is an essential part of caring for your dog. No dog should have to worry about this preventable disease.
                </p>
                <p>
                    Heartworm prevention is safe, affordable, and effective at preventing heartworm infection.
                    Although this infection is spread by mosquitoes, heartworm prevention should be used year-round,
                    regardless of how much time your dog spends inside or how much snow is on the ground.
                </p>
                <div className="Center" style={{paddingTop: 0}}>
                  <Link className="Button-blue" to="/dogs/heartworms">Learn more</Link>
                </div>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                  <img src={dog3} alt="Dog" />
                </div>
                <h2 className="H-turn Right">Spay & Neuter</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Spaying and neutering prevents many types of cancer, health problems, behavioral problems, and helps ensure your dog isn’t contributing to pet overpopulation.
                </p>
                <p>
                    There are a lot of good reasons to spay or neuter your dog and only a few good reasons not to.
                    Talk to your veterinarian about spaying and neutering and at what age it is best for your puppy.
                </p>
                <div className="Center" style={{paddingTop: 0}}>
                  <Link className="Button-blue" to="/dogs/spay_and_neuter">Learn more</Link>
                </div>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                  <img src={dog4} alt="Dog" />
                </div>
                <h2 className="H-turn">Vaccines</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Core vaccines are the vaccines that should be administered to your dog.
                </p>
                <p>
                    Canine distemper virus, parvovirus, adenovirus-2, and
                    leptospirosis vaccines are generally considered core vaccines for dogs in Hawaii. Rabies vaccination is required for dogs that travel outside of Hawaii.
                    Your dog may benefit from additional vaccines, and we’ll provide some information about those as well.
                </p>
                <div className="Center" style={{paddingTop: 0}}>
                  <Link className="Button-blue" to="/dogs/core_vaccines">Learn more</Link>
                </div>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                  <img src={dog5} alt="Dog" />
                </div>
                <h2 className="H-turn Right">Dental Care</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Proper dental care–including brushing–can keep that smile healthy.
                </p>
                <p>
                    Bad breath is just the tip of the iceberg; dental health can impact the overall
                    health of your dog. Whether or not you brush your dog's teeth daily, your dog may benefit
                    from regular dental cleanings from your veterinarian.
                </p>
                <div className="Center" style={{paddingTop: 0}}>
                  <Link className="Button-blue" to="/dogs/dental_care">Learn more</Link>
                </div>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                  <img src={dog6} alt="Dog" />
                </div>
                <h2 className="H-turn">Behavior</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Understanding dog behavior and proven training techniques will strengthen the relationship you have with your dog.
                </p>
                <p>
                    From puppy basics to correcting problem behaviors, proper training can help you build an amazing
                    relationship with your dog. We advocate for positive reinforcement, and we want to help
                    you have the best-behaved dog on the block.
                </p>
                <div className="Center" style={{paddingTop: 0}}>
                  <Link className="Button-blue" to="/dogs/behavior">Learn more</Link>
                </div>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                  <img src={dog7} alt="Dog" />
                </div>
                <h2 className="H-turn Right">Microchips</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Microchips are a safe, permanent way to identify your dog and get your dog back to you if your dog is ever lost.
                </p>
                <p>
                    Your dog wants to be microchipped.
                    Microchips are not GPS locators, but they are proven to help return lost dogs
                    to their families. Don't forget to register your dog's microchip!
                </p>
                <div className="Center" style={{paddingTop: 0}}>
                  <Link className="Button-blue" to="/dogs/microchips">Learn more</Link>
                </div>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                  <img src={dog8} alt="Dog" />
                </div>
                <h2 className="H-turn">Grooming</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Proper grooming keeps your dog looking sharp; it can also improve their health.
                </p>
                <p>
                    Brushing, bathing, nail trimming, and ear cleaning are just some of the dog grooming habits your dog wants you to know about.
                </p>
                <div className="Center" style={{paddingTop: 0}}>
                  <Link className="Button-blue" to="/dogs/grooming">Learn more</Link>
                </div>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                  <img src={dog9} alt="Dog" />
                </div>
                <h2 className="H-turn Right">Toxins</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Toxic plants, food, and other materials can be easily avoided if you know what to watch out for.
                </p>
                <p>
                    Chocolate, avocados, garlic, xylitol, and grapes are only a few examples of human-safe foods that can harm your dog.
                    It's also important to know which plants in your garden, or things in your home can be dangerous for your four-legged family member.
                </p>
                <div className="Center" style={{paddingTop: 0}}>
                  <Link className="Button-blue" to="/dogs/toxins">Learn more</Link>
                </div>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                  <img src={dog11} alt="Dog" />
                </div>
                <h2 className="H-turn">Loss & Grief</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Losing your dog can be heartbreaking, and it happens to every dog parent.
                </p>
                <p>
                    We want to empower you with information about making hard decisions around euthanasia
                    and give you some tools for healthy grieving. Seek professional help if you need it.
                </p>
                <div className="Center" style={{paddingTop: 0}}>
                  <Link className="Button-blue" to="/dogs/loss_and_grief">Learn more</Link>
                </div>
                <div className="Center" style={{padding: '0 0 2.5rem 0'}}>
                  <img src={dog12} alt="Dog" />
                </div>
            </div>
        );
    }

}
