import React, { Component } from 'react';
import "../Content.css";

export default class NutritionSub extends Component {
    render() {
        return (
            <div className="C-width">
                <p className="References">
                    <sup>1</sup>Hand, MS; Lewis, LD. <em>Small Animal Clinical Nutrition</em>. Mark Morris Institute. 2010.
                </p>
            </div>
        );
    }

}
