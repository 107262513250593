import React, { Component } from 'react';
import "../Content.css";
import dog2 from '../../images/dogs/dog002.png';
import dog9 from '../../images/dogs/dog009.png';
import dogWet from '../../images/dogs/dogWet.png';
import dog3 from '../../images/dogs/dog003.png';
import dog12 from '../../images/dogs/dog012.png';
import puppyBeagle from '../../images/dogs/puppyBeagle.png';

export default class Grooming extends Component {
    render() {
        return (
            <div className="C-width">
                <h1>Grooming</h1>
                <p>
                    "Let them smell like a dog as long as you can stand it . . . no dog wants to smell like a bathtub that has had a dog in it" &mdash; Alexandra Horowitz, <em><a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/1416583432/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=1416583432&linkId=9a5f88f8a5636d5436eeaef41fbb6f42">Inside of a Dog</a></em>
                </p>
                <h2 className="H-turn">Main Points</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Start teaching your dog that grooming is fun as early as possible, preferably as a puppy.
                </p>
                <p>
                    It is important to start playing with a puppy's ears and feet early.  Teach them that it is fun and normal for you to hold each ear or each toe.  You don't need to actually clean the ears or clip the toenails, but make sure to give lots of rewards and reinforce 
                    healthy interactions that will make grooming easier as they age.  Dogs that have not been introduced to the idea of an ear cleaning or nail clipping may be fearful of the experience later.  If you have an older dog that is not at all happy about a nail trimming, then it will take some training effort to warm your dog up to this activity.  The same advice applies to bathing. With constant human supervision, teach your puppy that bath time is a fun, reward-filled experience.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Regular grooming is an excellent way to screen your dog for some of the health problems that affect the skin, ears, or nails.
                </p>
                <p>
                    Not only will positive grooming experiences strengthen the bond you have with your dog, they are also an opportunity to identify any skin issues, 
                    lumps or bumps, or other changes early.  Many diseases can cause changes to your dog's skin or coat.  Regular grooming can cue you in to 
                    changes that you can bring to the attention of your veterinarian.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Different dogs have different grooming needs.
                </p>
                <p>
                    It's no surprise that an Afghan Hound will require more grooming than a Doberman.  Different dogs have different coats and different grooming needs.  We have tried to provide some helpful generalizations about grooming below, but they do not necessarily apply to all dogs.  Your veterinarian is an excellent resource if you have specific questions about grooming your dog.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={dog2} alt="Dog" />
                </div>
                <h2 className="H-turn Right">Brushing</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Regular brushing can help remove dead hairs, prevent tangles, and reduce shedding.
                </p>
                <p>
                    For most medium coats, a <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B00ZGPI3OY/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B00ZGPI3OY&linkId=28c31e29ebe6eaaba1712939f7de0ee9">slicker brush</a> should be used.
                    Brush gently, be patient, and take frequent breaks if needed so that your dog enjoys the entire process.  
                    Longer haired dogs may do better with a <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B0002RJMA0/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B0002RJMA0&linkId=9d8dc963e60e32fbd91db92f6e6929a7">pin and bristle brush</a>, and 
                    short haired dogs may prefer a <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B0056V9S4M/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B0056V9S4M&linkId=731e74d8f418d81cee95d7d2b68d4c6b">rubber brush</a>.
                    Regular brushing will prevent mats, or knots of hair, from forming.  Start by brushing weekly, and decrease or increase the frequency as you learn what is necessary to keep your dog's coat tangle free.  
                    Some mats will be too knotted to brush out. <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B0002ARR2W/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B0002ARR2W&linkId=b8e128608602aa7e3de309faf9d54d2a">Dematting combs</a> can be used, but must be used properly or their sharp blades can cut your dog's skin.
                    We recommend a <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B00A8D95OG/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B00A8D95OG&linkId=ca0cbecf64522e37976ebb8055cfb049">clipper</a> for removing mats, especially in tough areas with more delicate skin, like under the arms, in the groin, and near the ears.
                    On that note, it is a common misconception that dogs need to be clipped in the summer months to help dissipate heat.  Dogs dissipate heat mostly through their tongues and paw pads, so cutting your dog's coat will not help it stay cool.  In fact, it may increase the risk of a sunburn.  If you would like to use a scissors to trim the feathers, skirt, ears, or face, 
                    we recommend a <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B01EFLKZ7C/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B01EFLKZ7C&linkId=d15e2db9aa2bf908a860dba7cfec3efb">scissors with a rounded tip</a>, a skilled and steady hand, and a very well behaved dog.  
                    It is also an excellent idea to brush your dog before bathing. This will prevent the worsening of tangles and help reduce the amount of hair that could clog your drain.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={dog9} alt="Dog" />
                </div>
                <h2 className="H-turn">Bathing</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Bathing your dog can make for a fresh, clean look.  Bathing can also be necessary after your dog makes a questionable decision to roll in something unpleasant.
                </p>
                <p>
                    Dogs have natural oils that coat and protect their skin, and they prefer their own dog smell. For these reasons, most dogs should not be bathed more than once per month, 
                    unless directed to do so by your veterinarian.  
                    Use a gentle veterinary shampoo.  Dog hair root design 
                    differs slightly from humans, so human products are often too harsh for your dog.  Several quality shampoos are on the market.  
                    We have had great success with <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B007IM3RZI/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B007IM3RZI&linkId=c86cfee3472bac9c2ddf519a8167d1ab">Mane 'n Tail</a> shampoo, <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B001XQ7LOS/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B001XQ7LOS&linkId=82fadd22619166f74e2f86b9b855fe30">Dawn dish soap</a> (a gentle alternative to pet shampoo), 
                    and <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B002WDB7UU/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B002WDB7UU&linkId=11d253e3177d772d0eef28df5e9f216c">Dechra</a> shampoo (also available through your veterinarian).  First, thoroughly wet your dog with cool to room temperature water. Warm water is too hot for dogs and can irritate their skin.  Be gentle as you wash, avoid getting water in your dog's eyes or ears, and rinse thoroughly.  You can gently towel dry your dog, working with the direction of the hair, or use a hairdryer (on cool) if your dog will tolerate it.  Many dogs are too afraid to enjoy the hair dryer and will require some training or a different drying method.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={dogWet} alt="Dog" />
                </div>
                <h2 className="H-turn Right">Nail Clipping</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Regular nail clipping is an important grooming habit for all dogs.
                </p>
                <p>
                    Use a sharp, <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B0002RJM8C/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B0002RJM8C&linkId=f47140464a3d86efeb0836725afe9846">veterinary nail trimmer</a> to clip your dog's nails.
                    The nails on the front paws usually require more frequent trimming than the nails on the rear paws. Dogs have living tissue, called the quick, in the center of each nail that contains a blood supply and nerve.  
                    If your dog has clear nails, this is pretty easy to see and avoid, and you'll want to trim your dog's nails to a point just before you would hit the quick.  If your dog has black nails, you will not be able to see the quick, 
                    so you'll want to take a conservative amount of nail until you see a grey or pink oval of tissue appear in the middle of the cut surface.  If your dog's nail starts to bleed, then you have gone too far and cut the quick.
                    If this happens, it is a good idea to have some <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B0002H3RBU/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B0002H3RBU&linkId=71ddd50254bda8274d09f6ac7aa2b188">styptic powder</a> ready to stop the bleeding.  
                    Simply press a small amount of this powder onto the bleeding quick.  In a pinch, some flour or cornstarch may work to stop a bleeding quick.  If your dog has dewclaws, don't forget to trim these nails as well.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={dog3} alt="Dog" />
                </div>
                <h2 className="H-turn">Ear Cleaning</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Most dogs benefit from regular ear cleanings, and some dogs require this to prevent ear disease.
                </p>
                <p>
                    Ear infections are a common health problem of dogs.  Cleaning your dog's ears after bathing or swimming can help remove debris and moisture that may contribute to an ear infection.  The dog's ear canal is L-shaped, so you should never use a cotton swab in the ear. 
                    This will only push wax and debris back in the ear and out of reach which will contribute to future ear problems.  We recommend saturating a cotton ball with <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B00K4X8RZ8/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B00K4X8RZ8&linkId=dc866ed3f4c482adb819c4ccc20341db">veterinary ear cleaner</a>, squeezing this into the ear, 
                    and gently cleaning the visible part of the canal with the cotton ball. You can then gently massage the base of the ear before your dog quickly shakes the ear cleaner out.  Do not clean your dog's ears if they are already red or inflamed without first consulting with your veterinarian.  
                    Some ear cleaners can damage the sensitive parts of an inflamed ear.  Talk to your veterinarian if either of your dog's ears appear inflamed or if you're noticing increased head shaking or increased scratching at the ears&ndash;both signs that your dog may have an ear problem.  
                    In some dogs with floppy ears, there is significant hair visible in the outermost part of the ear canal.  This hair can trap moisture and debris in the ear.  These dogs may benefit from pulling this hair every few months.  
                    If your veterinarian suggests this, you'll want to use an <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B0002ASICK/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B0002ASICK&linkId=77f7fd8d62fbbb0d6982e70e07ac4e52">ear powder</a> and a non-locking <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B0014A6B6W/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B0014A6B6W&linkId=641efc8edde26f2701f1fd92d6ae5f29">hemostat</a> to pull these hairs out.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={dog12} alt="Dog" />
                </div>
                <h2 className="H-turn Right">Anal Glands</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    For certain pet parents, it may be necessary to occasionally express your dog's anal glands.
                </p>
                <p>
                    We fully understand if you are not up for this unpleasant challenge, and you can always pass this chore to a veterinary professional.  If your dog is scooting around, one possible explanation is that your dog's anal glands are uncomfortably full and need to be expressed.  These normally pea-sized glands are found at the 8 o'clock and 4 o'clock position just inside your dog's rectum.  With a gloved hand, you can use your thumb and index finger to gently squeeze the smelly fluid out and relieve your dog's discomfort.  
                    There are special <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B01MQ3GRGK/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B01MQ3GRGK&linkId=1eb30b76df64f4e2b94ddfbba1957a7e">cleaning wipes</a> to clean the area around your dog's anus after this smelly process, but we still recommend doing this chore outside.  Some dogs need this performed regularly, and some dogs never need it performed.
                </p>
                <div className="Center">
                    <img src={puppyBeagle} alt="Dog" />
                </div>
            </div>
        );
    }   
    
}