import React, { Component } from 'react';
import "../Content.css";
import cat26 from '../../images/cats/cat026.png';
import cat29 from '../../images/cats/cat029.png';
import nyx3 from '../../images/cats/nyx03.png';

export default class CatGrief extends Component {
    render() {
        return (
            <div className="C-width">
                <h1>Loss & Grief</h1>
                <p>
                    "It’s very important to complete the unfinished aspects of any relationship so that we can achieve our real goals of maintaining the loving memories and feelings as long as we are alive." &mdash; <a target="_blank" rel="noopener noreferrer" href='https://www.amazon.com/gp/product/1630760145/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=1630760145&linkId=ab17a938a030b7bbccd439d974be2851'><em>The Grief Recovery Handbook for Pet Loss</em></a>
                </p>
                <h2 className="H-turn">Aging</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    The aging process is normal in cats, and as most age, they develop health problems that require some extra care.
                </p>
                <p>
                    It's important to remember that cats age more quickly than humans.  With proper care, cats can live long healthy lives into their teens, with some living 20 years or more.  Better veterinary care with a focus on preventative medicine allows us to maximize the healthy life of our four-legged family members. Talk with your veterinarian about the aging process and how you can help your cat age gracefully.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={cat26} alt="Cat" />
                </div>
                <h2 className="H-turn Right">Euthanasia</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Euthanasia means a "good death."
                </p>
                <p>
                    At a point near the end of your cat's life, it may be the kindest thing you can do for your loved one.
                    There is no easy way to make generalizations about when it is time to euthanize your cat.
                    It may be helpful to make a list of what makes your cat happy or unhappy.
                    This list can be a helpful reminder as your cat nears the window of time when euthanasia should be considered.
                    Your veterinarian can provide you with more information around this difficult decision, so have a conversation with him or her about euthanasia well before this time comes.
                    There are exceptionally advanced medical and pain management options that can help slide this window of time to a later date, and your veterinarian is the best resource to guide you in these decisions.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={cat29} alt="Cat" />
                </div>
                <h2 className="H-turn">Grief</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    There is nothing harder than losing a family member.  Acknowledge the grief and make room for it.
                </p>
                <p>
                    All pet parents will experience this loss.  It is important to acknowledge and make room for the grief that will come with the loss of your cat.  Sometimes you can anticipate and prepare for this grief, but other times it is a sudden loss.  The grieving process may even begin leading up to the loss of your cat, as is often the case when you learn that your cat is terminally ill.  It is healthy and normal to grieve, and people grieve in many different ways.  <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/1630760145/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=1630760145&linkId=46ae6cc208725f336df5c7e77686b4e5">The Grief Recovery Handbook for Pet Loss</a> is an excellent resource for pet parents.  <a target="_blank" rel="noopener noreferrer" href="https://aplb.org/">The Association for Pet Loss and Bereavement</a> has additional resources to help you through your loss. They also provide moderated chat rooms for discussions of pet loss. In many cases of grief, it is beneficial to seek professional help from a psychiatrist or therapist with additional training in grief.  If your grief shows no signs of improving, or is causing you to be dysfunctional at work or home, seek professional help.  Call 911 if you are experiencing a mental health emergency.
                </p>
                <div className="Center">
                    <img src={nyx3} alt="Cat" />
                </div>
            </div>
        );
    }

}
