import React, { Component } from 'react';
import "../Content.css";
import cat14 from '../../images/cats/cat014.png';
import cat16 from '../../images/cats/cat016.png';
import saynocat from '../../images/cats/saynocat.png';

export default class CatDentalCare extends Component {
    render() {
        return (
            <div className="C-width">
                <h1>Dental Care</h1>
                <p>
                    "Periodontal disease is the most common clinical condition affecting adult pets, despite the fact that it is almost entirely preventable." &mdash; <a target="_blank" rel="noopener noreferrer" href='https://www.avdc.org/ownersinfo.html'><em>American Veterinary Dental College</em></a>
                </p>
                <h2 className="H-turn">Main Points</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Dental health problems can cause bad breath, pain, and more serious systemic disease in your cat.
                </p>
                <p>
                    Neglecting dental care in your cat can cause bad breath, a painful mouth, and even heart, kidney, or liver problems.  Yearly dental cleanings, performed under anesthesia at a veterinary clinic, can help your cat avoid more serious diseases.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    You can help prevent dental diseases with daily teeth brushing, dental treats, water additives, and teeth-friendly toys.
                </p>
                <p>
                    Additional at-home tips and tools to help you keep your cat's smile healthy are provided below.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Talk to your veterinarian about your cat's dental care and when to schedule your cat's next dental cleaning.
                </p>
                <p>
                    One annual dental cleaning is recommended for most cats, but every cat is different and some would benefit from more frequent cleanings. Diet, lifestyle, and genetics all contribute to the dental health of your cat. Your veterinarian can make the most informed recommendation on how to provide the best dental care for your cat.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={cat14} alt="Cat" />
                </div>
                <h2 className="H-turn Right">At Home</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Daily Brushing
                </p>
                <p>
                    Daily brushing is a great way for cat parents to help slow the buildup of bacterial plaque on their cat's teeth.
                    We understand that most parents will not be brushing their cat's teeth, but if you want to give it a try we're thrilled!  It's the single best thing you could do for your cat's oral health, and your cat may even enjoy it. It's important to take the time to train your cat to accept and enjoy teeth brushing, so make gradual steps to get there.
                    It is often necessary to first train your cat to accept a gentle single-tooth brushing with your finger, a piece of gauze wrapped around your finger, or a fingerbrush like the one included
                    in <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B004ULZ2PI/ref=as_li_qf_asin_il_tl?ie=UTF8&tag=dogscatsmon0a-20&creative=9325&linkCode=as2&creativeASIN=B004ULZ2PI&linkId=d4b6714473d66ac3d735f4fb909e66c2">this kit</a>.
                    It is very important to use a <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B004ULYZ5Q/ref=as_li_qf_asin_il_tl?ie=UTF8&tag=dogscatsmon0a-20&creative=9325&linkCode=as2&creativeASIN=B004ULYZ5Q&linkId=e442a4d69636f7769f104ad0a7b03237">cat toothpaste</a> which will not contain fluoride like human toothpaste.
                    Although it may be the single best thing you could do for your cat's oral health, daily brushing is not appropriate for every cat or every cat parent.  If it isn't enjoyable for you and your cat, we don't recommend it.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Dental Treats
                </p>
                <p>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B071YQWQGQ/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B071YQWQGQ&linkId=722b3ca7800f959038e5cac4f078acf2">Dental treats</a> that are stamped with the Veterinary Oral Health Council's stamp of approval can help keep your cat's teeth healthy. They should be chewed daily to be effective, and if your cat is not into chewing when it eats, dental treats may do nothing.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Water Additives
                </p>
                <p>
                    There are a lot of water additives that claim to improve your cat's oral health.  <a target="_blank" rel="noopener noreferrer" href="https://www.healthymouth.com/Articles.asp?ID=253">HealthyMouth</a> water additive is the only one that has the Veterinary Oral Health Council's stamp of approval. <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/search?ie=UTF8&tag=dogscatsmon0a-20&linkCode=ur2&linkId=e199d299ab28f839576778a234445f94&camp=1789&creative=9325&index=pets&keywords=cat water additive">Other products</a> are out there and have worked well for some cats.  It's important to keep in mind that these products are not as effective as daily brushing and are not a replacement for regular dental cleanings from a veterinarian.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Chew Toys
                </p>
                <p>
                    Some cats love to chew. If your cat loves to chew, there are <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B0009YF3KY/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B0009YF3KY&linkId=14c0c64d85645bf1d2bb60085f205439">toys</a> and <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B075ZRZC1L/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B075ZRZC1L&linkId=ad630db40b8077f8297b275fe55cf1ec">sticks</a> that may reduce tartar buildup as your cat chews.  Again, this is not a replacement for regular dental cleanings from a veterinarian.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={cat16} alt="Cat" />
                </div>
                <h2 className="H-turn">At your Vet</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Your veterinarian is only able to properly evaluate your cat's teeth while your cat is under anesthesia.
                </p>
                <p>
                    Your veterinarian may get a brief look in your cat's mouth during a physical exam which can give some information about your cat's oral health. Since most dental disease hides below the gumline, it is important to schedule regular prophylactic dental cleanings with your veterinarian. While your cat is under anesthesia, your veterinarian will clean beneath the gumline and take dental radiographs if significant periodontal disease is suspected. For this reason, anesthesia-free dentals are not recommended.  The <a target="_blank" rel="noopener noreferrer" href="http://avdc.org/AFD/">American Veterinary Dental College</a> has more information to educate cat parents on the risks associated anesthesia-free dentistry.
                </p>
                <div className="Center">
                    <a target="_blank" rel="noopener noreferrer" href="http://avdc.org/AFD/"><img src={saynocat} alt="Cat" /></a>
                </div>
            </div>
        );
    }

}
