import React, { Component } from 'react';
import "../Content.css";
import catTrain from '../../images/cats/catTrain.png';
import catYellow from '../../images/cats/catYellow.png';
import cat1 from '../../images/cats/cat001.png';
import cat25 from '../../images/cats/cat025.png';

import catFoodOld from '../../images/cats/catFoodOld.png';

export default class CatNutrition extends Component {
    render() {
        return (
            <div className="C-width">
                <h1>Nutrition</h1>
                <p>
                    "The positive impact of proper nutrition on health and disease is well established in all animals. Appropriate feeding throughout all life stages
                    can help prevent diet-associated diseases, as well as to assist in the management of other
                    diseases." &mdash; <a target="_blank" rel="noopener noreferrer" href='https://www.aaha.org/public_documents/professional/guidelines/nutritionalassessmentguidelines.pdf'><em>AAHA Nutritional Assessment Guidelines for Dogs and Cats</em></a>
                </p>
                <h2 className="H-turn">Main Points</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Feed your cat high quality food appropriate for your cat's life stage from a trusted pet-food brand.
                </p>
                <p>
                    An AAFCO Nutritional Adequacy Statement that indicates the food is complete and balanced for the appropriate life stage of cat is a good place to start.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Monitor your cat's weight and body condition score to ensure your cat’s food intake is appropriate.
                </p>
                <p>
                    Cat obesity has been linked to several health problems.  The nutritional requirements of individual cats vary with breed, activity level, and life stage, among other factors.  Feeding an appropriate quantity and keeping treats to less than 10% of the daily calories consumed by your cat can help ensure your cat lives a long, healthy life.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Cats can become particular about the size, shape, and texture of their food.
                </p>
                <p>
                    For this reason, it can be advantageous to introduce your kitten to different styles of both wet and dry food.  Make sure the foods are compatible and balanced.  If your cat is a picky eater, it may not be possible to switch foods or food type.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    Talk with your veterinarian and find a specific diet and feeding plan that work for your cat.
                </p>
                <p>
                    What and how you feed your cat is an important part of the bond you create with your four-legged family member. Have a conversation with your veterinarian at each visit to make sure you understand and meet the unique nutritional requirements of your cat.
                </p>
                <p style={{fontWeight: 'bold'}}>
                    If your cat stops eating for any reason, contact your veterinarian.
                </p>
                <p>
                    Cats that stop eating are usually giving us a sign that they are ill, and anorexia can quickly make them much more ill.  Cats don't have the same ability to store energy as humans do, so if your cat stops eating, contact your veterinarian as soon as possible.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={catTrain} alt="Cat" />
                </div>
                <h2 className="H-turn Right">Quality</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    An AAFCO Nutritional Adequacy Statement found on your cat food will tell you if the diet is complete and balanced and for what life stage it is intended.
                </p>
                <p>
                    This statement is not a guarantee that the food is appropriate for your cat, but it's an indication that you're on the right track. Hill's, Purina, and Royal Canin are the three big names in pet food and are generally well-trusted by the veterinary community.
                    There are many other pet food manufacturers, and some of them also make quality food. Raw food diets increase the risk of <em>Salmonella</em> and <em>Listeria</em>, which cause dangerous disease in your cat, so these are not usually recommended by veterinarians. A list of FDA recalls can be found <a target="_blank" rel="noopener noreferrer" href='https://www.fda.gov/AnimalVeterinary/SafetyHealth/RecallsWithdrawals/default.htm'>here</a> and demonstrates several recent recalls attributed
                    to <em>Salmonella</em> and <em>Listeria</em>. Multiple studies indicate that most homemade diets are not balanced or complete<sup>1</sup>, so these diets are usually not recommended by veterinarians.  A great deal of marketing goes into pet foods, and it's important to remember that the guaranteed analysis (the amounts of protein, fat, fiber, and moisture) published on the pet food is not an indication of nutritional quality.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={catYellow} alt="Cat" />
                </div>
                <h2 className="H-turn">Quantity</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    The amount you feed your cat and your cat's subsequent caloric intake are important in preventing the health consequences associated with feline obesity.
                </p>
                <p>
                    Many diseases in cats have known associations with obesity, and too many cats in the U.S. are overweight.  Weighing your cat monthly and recording these weights over time can help you make sure your cat is getting enough, but not too many calories. You can also monitor your cat's body condition score, or BCS, monthly to make sure your cat is staying at a healthy weight.  Check out <a target="_blank" rel="noopener noreferrer" href="http://www.wsava.org/WSAVA/media/PDF_old/Body-condition-score-chart-cats.pdf">this resource</a> from the World Small Animal Veterinary Association  for more information on how to monitor the BCS of your cat.  Growing kittens and reproducing cats require more calories and often a more energy dense food than adult cats that have been spayed or neutered.  It's important to remember that treats should not make up more than 10% of your cat's caloric intake.
                </p>
                <div className="Center" style={{padding: '0 0 1.5rem 0'}}>
                    <img src={cat1} alt="Cat" />
                </div>
                <h2 className="H-turn Right">Feeding Plan</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Some cats can maintain a healthy weight with free access to a full food bowl, but others require a more structured feeding plan.
                </p>
                <p>
                    Giving free access to food can be convenient for pet parents, and works well for many cats, but others may overeat and gain weight.  Regularly scheduled meals two to three times per day can prevent weight gain associated with overeating.  <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/gp/product/B01N90VHZW/ref=as_li_tl?ie=UTF8&tag=dogscatsmon0a-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B01N90VHZW&linkId=329ecc5982256bbb8cde379f422da09b">Interactive feeding toys</a> are a great option for some cats and can help encourage exercise and prevent boredom.  Cats can be picky eaters and often develop a preference for a particular shape or type of dry or wet foods.  Exposing your kitten to multiple types of dry and wet foods that are compatible and balanced can help prevent food aversions later in life.  Talk with your veterinarian about the best feeding plan for your cat.
                </p>
                <div className="Center">
                    <img src={catFoodOld} alt="Cat" />
                </div>
                <h2 className="H-turn">Additional Resources</h2>
                <p className="P-top" style={{fontWeight: 'bold'}}>
                    Web Links
                </p>
                <p>
                    <a target="_blank" rel="noopener noreferrer" href='https://vet.osu.edu/vmc/companion/our-services/nutrition-support-service/nutrition-consult-request'><em>The Ohio State University</em></a> is available for veterinary nutrition consultation and can help tailor a diet, home-cooked or commercial, to your individual cat.
                </p>
                <p>
                    <a target="_blank" rel="noopener noreferrer" href='https://secure.balanceit.com'>Balance IT</a> was created by a boarded veterinary nutritionist to help ensure home-cooked diets are complete and balanced.
                </p>
                <p>
                    <a target="_blank" rel="noopener noreferrer" href='https://www.amazon.com/dp/B01N90VHZW/?coliid=I2W86H38G8GE4J&colid=2I9V2KLX6DRN1&psc=0&ref_=lv_ov_lig_dp_it'>Interactive Feeding Toys</a>, such as these indoor hunting toys, can help make meal time fun and engaging and keep your cat mentally stimulated.
                </p>
                <p>
                    <a target="_blank" rel="noopener noreferrer" href='https://www.amazon.com/dp/B01JM4XK9W/?coliid=I1J1V4HN7KA1ZL&colid=2I9V2KLX6DRN1&psc=0&ref_=lv_ov_lig_dp_it'>Microchip feeders</a> can be used to ensure cats in multi-cat households eat only their prescribed diet and are especially useful if one cat needs a disease-specific veterinary diet.  These feeders are also useful outdoors and can prevent kitty visitors from sharing your cat's meals.
                </p>
                <div className="Center">
                    <img src={cat25} alt="Cat" />
                </div>
            </div>
        );
    }

}
