import React, { Component } from 'react';
import "../Content.css";

export default class CatVaccinesSub extends Component {
    render() {
        return (
            <div className="C-width">
                <p className="References">
                    <sup>1</sup>2013 AAFP Feline Vaccination Advisory Panel Report. <em>Journal of Feline Medicine and Surgery</em>. (2013) 15, 785-808.
                </p>
            </div>
        );
    }   
    
}